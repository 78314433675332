@import '../themes/themes';
@import '~sassdash';

// https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9

$spaces: (0.1, 0.2, 0.4, 0.5, 0.7, 1, 1.5, 2);
$sides: (top, bottom, left, right);

@each $space in $spaces {
    //noinspection SassScssResolvedByNameOnly
    $className: _pad-left($space * 10, 2, '0');
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$className} {
            margin-#{$side}: #{$space}rem;
        }

        .p#{str-slice($side, 0, 1)}-#{$className} {
            padding-#{$side}: #{$space}rem;
        }
    }

    .mx-#{$className},
    .m-#{$className} {
        margin-left: #{$space}rem;
        margin-right: #{$space}rem;
    }

    .my-#{$className},
    .m-#{$className} {
        margin-top: #{$space}rem;
        margin-bottom: #{$space}rem;
    }

    .px-#{$className},
    .p-#{$className} {
        padding-left: #{$space}rem;
        padding-right: #{$space}rem;
    }

    .py-#{$className},
    .p-#{$className} {
        padding-top: #{$space}rem;
        padding-bottom: #{$space}rem;
    }
}

.ml-a,
.mx-a,
.m-a {
    margin-left: auto;
}

.mr-a,
.mx-a,
.m-a {
    margin-right: auto;
}

.mt-a,
.my-a,
.m-a {
    margin-top: auto;
}

.mb-a,
.my-a,
.m-a {
    margin-bottom: auto;
}

.c-grab {
    cursor: grab;
}

.c-colresize {
    cursor: col-resize;
}

.c-hand {
    cursor: pointer;
}

.c-cell {
    cursor: cell;
}

.c-default {
    cursor: default;
}

.d-block {
    display: block !important;
}

.d-inline {
    display: inline !important;
}

.d-inlineb {
    display: inline-block !important;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.d-invisible {
    visibility: hidden;
}

.d-none {
    display: none !important;
}

.text-clip {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}

.column-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.row-container {
    flex-direction: row;
    width: 100%;
}

.flex-vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-1,
.flex-1-set {
    flex: 1;
}

.flex-1-set {
    height: 1px;
}

.flex-0-1 {
    flex: 0 1;
}

.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.ox-h,
.o-h {
    overflow-x: hidden !important;
}

.oy-h,
.o-h {
    overflow-y: hidden !important;
}

.ox-s,
.o-s {
    overflow-x: scroll !important;
}

.oy-s,
.o-s {
    overflow-y: scroll !important;
}

.ox-a,
.o-a {
    overflow-x: auto !important;
}

.oy-a,
.o-a {
    overflow-y: auto !important;
}

.va-t {
    vertical-align: top;
}

.va-m {
    vertical-align: middle;
}

.va-b {
    vertical-align: bottom;
}

@mixin text-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ws-nw {
    white-space: nowrap;
}

.wb-ba {
    word-break: break-all;
}

.wb-bw {
    word-break: break-word;
}

.wb-ka {
    word-break: keep-all;
}

.text-strike {
    text-decoration: line-through;
}
.text-left {
    text-align: left !important;
}
.text-ellipsis {
    @include text-ellipsis();
}

.pos-r {
    position: relative;
}

.pos-a {
    position: absolute;
}

.fw-n {
    font-weight: normal;
}

.fw-500 {
    font-weight: 500;
}

.fw-b {
    font-weight: bold;
}

.fw-ber {
    font-weight: bolder;
}

.clr-col-0 {
    width: 100%;
    min-height: 1px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex: 0 1 0;
    max-width: 100%;
}

.fck-word-breaker {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    //word-break: break-word;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.d-flex {
    display: flex;
}
