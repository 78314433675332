.gantt_layout_content {
    min-height: 50vh;
}

.gantt_grid_head_cell.gantt_grid_head_add {
    display: none;
}

.no_drag_progress .gantt_task_progress_drag {
    display: none !important;
}

.gantt_task_row.gantt_selected .gantt_task_cell {
    border-right-color: #5dcfe3;
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected {
    background-color: #5dcfe3;
}

.gantt_grid_data .gantt_row:hover,
.gantt_grid_data .gantt_row.odd:hover {
    background-color: #5dcfe3;
}

.gantt_task_row.gantt_selected {
    background-color: #5dcfe3;
}
