@import '../themes/themes';
@import '~@ng-select/ng-select/themes/material.theme.css';

// -------------- SELECT
.ng-select {
    margin-top: 0.1rem;
    *:not(button) {
        font-size: 0.7rem;
    }
}

.ng-select .ng-value-container {
    border-top: 1rem solid transparent;
}

.ng-select .ng-value {
    margin-top: 0.2rem;
}

.ng-select .ng-clear-wrapper {
    position: unset;
}

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: unset;
    bottom: unset;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding: 0;
}

.ng-select.less-pl .ng-option {
    padding-left: 0.4em !important;
}

.ng-select.pr-0 .ng-option {
    padding-right: 0 !important;
}

.ng-select.ng-select-focused .ng-select-container::after {
    @include themify() {
        border-bottom-color: theme('primary-color');
    }
}

.ng-select.ng-select-multiple {
    .ng-select-container .ng-value-container .ng-value {
        background-color: transparent;
        padding: 0;
        margin: 0;

        & > span.label {
            padding: 0.5rem 0.4rem;
            font-size: 0.7rem;
        }
    }
}

ng-select.petitSelect {
    > .ng-select-container {
        width: 75%;
    }
}

.ng-select.no-border .ng-select-container {
    min-height: auto;
    .ng-value-container {
        border-top: 0;
    }
}

.ng-select.hidden-arrow {
    .ng-arrow-wrapper {
        display: none;
    }
}

.ng-option {
    app-long-avatar {
        .label {
            line-height: 3;
        }
    }
}

// -------------- DROPDOWN
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default;
    text-align: center;
}

// -------------------------- ngx chart
.ngx-charts {
    float: right !important;
    position: relative !important;
}

ngx-charts-number-card .cell .trimmed-label p {
    line-height: 20px !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item:hover {
    @include themify() {
        color: theme('primary-color');
    }
}

.chart-legend {
    position: relative;
    .legend-labels {
        max-width: 8rem;
        line-height: 85%;
        list-style: none;
        text-align: left;
        float: left;
        width: 218% !important;
        border-radius: 3px;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: nowrap;
    }
}
.chart-legend .legend-label {
    .active .legend-label-text {
        @include themify() {
            color: theme('primary-color');
        }
    }
    .legend-label-text {
        @include themify() {
            color: theme('textColor');
        }
    }
}

ngx-charts-chart {
    @include themify() {
        fill: theme('textColor');
    }
}

ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    @include themify() {
        color: theme('textColor');
    }
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    @include themify() {
        color: theme('textColor');
    }
}

// -------------------------------------------
// ADMIN - ACCESS CONTROL
.ng-select.ng-select-admin-access-control .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 50%;
}
// -------------------------------------------

ng-dropdown-panel.dropdown-auto {
    width: auto !important;
    min-width: 100%;
}
