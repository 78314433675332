$randomColors: (
    'orange': #ff4c00,
    'purple': #9d5973,
    'yellow': #ffba10,
    'blue': darken(mix(#39cccc, #7fdbff), 15%),
    'red': #ff4136,
    'slateblue': #70acb5,
    'yellowgreen': yellowgreen,
    'darkturquoise': darkturquoise,
    'teal': teal,
);

$common: map_merge(
    $randomColors,
    (
        'gray': #3c3c3c,
        'light-gray': #898989,
        'gold': #b27c00,
        'green-1': darken(#99e15a, 5%),
        'green-2': mix(#3d9970, #2ecc40),
        'green': mix(darken(#99e15a, 5%), mix(#3d9970, #2ecc40)),
        'primary-color': #009bff,
        'success-color': #31a152,
        'error-color': #ff0000,
        'selected': #1963ff,
        'task-color': #4caf50,
        'time-color': #e64a19,
        'project-color': #7e57c2,
        'backup-color': #af6990,
        'request-new': #d93b67,
        'request-open': darkturquoise,
        'white': whitesmoke,
        // TODO : à revoir, duplique les couleurs mais plus explicite pour les css quand on code
            'task-backlog-color': #70acb5,
        'task-todo-color': mix(darken(#99e15a, 5%), mix(#3d9970, #2ecc40)),
        'task-inprogress-color': #ffba10,
        'task-review-color': #ff4c00,
        'task-reviewing-color': #9d5973,
        'task-done-color': darken(mix(#39cccc, #7fdbff), 15%),
        'milestone-reminder-color': #ffba10,
        'milestone-meeting-color': darken(mix(#39cccc, #7fdbff), 15%),
        'milestone-call-color': mix(#3d9970, #2ecc40),
        'milestone-deadline-color': #ff4136,
        'milestone-release-color': #ff4c00,
        'request-defined': #b27c00,
        'request-agreed': mix(#3d9970, #2ecc40),
        'roadmap-red': #fd6259,
        'roadmap-green': #63ad79,
    )
);

$themes: (
    'light':
        map_merge(
            $common,
            (
                'inverse': #000,
                'base': #fff,
                'main_background': #fff,
                'card_background': #fff,
                'sidepanel': #eee,
                'hover_list': #eee,
                'border': #a8a8a8,
                'textColor': #565656,
                'panel': #e4e4e4,
                'navHover': #ccc,
                'light_gray': #737373,
                'focus': #6bc1e3,
                'active_link': #9b56bb,
                'visited': #5659b9,
                'labelColor': #444,
                'modal': #fff,
                'wizard_nav': #fafafa,
                'wizard_nav_title': #313131,
                'wizard_content': #fff,
                'wizard_active_step': #d9e4ea,
                'card_header': #50596c,
                'time_bg': #f0f0f0,
                'breadcrumb_item': #667189,
                'datagrid_spinner': rgba(255, 255, 255, 0.6),
                'placeholder': rgba(0, 0, 0, 0.54),
                'search-color': #1963ff,
                'level-blue': #1963ff,
                'level-purple': #9d5973,
                'level-green': #37a93d,
                'level-yellow': #7a7f33,
                'level-red': #ff3523,
                'ql-editor-placeholder': rgba(39, 39, 34, 0.6),
                'milestone-color': darken(#ffc107, 15%),
                'popover-shadow': rgba(69, 77, 93, 0.3),
                'note-shadow': rgba(0, 0, 0, 0.1),
                'note-shadow-hover': rgba(0, 0, 0, 0.17),
                'dg-selected-bg': #d9e4ea,
                'dg-selected-text': #fff,
                'dg-hover-bg': #eee,
                'dg-bg': #fafafa,
                'dg-border': #ccc,
                'btn-secondary-border': #0079b8,
                'btn-secondary-color': #0079b8,
                'boardBg': linear-gradient(#fff, rgba(246, 246, 255, 0.14)),
                'boardShadow': (
                    inset 0 0 2px 2px rgba(0, 0, 0, 0.05),
                    inset 0 30px 30px rgba(203, 203, 203, 0.11),
                ),
                'message-theirs': #efefef,
                'message-mine': #6ed4ce,
                'border-light': #eee,
            )
        ),
    'dark':
        map_merge(
            $common,
            (
                'inverse': #fff,
                'base': #303d4f,
                'main_background': #21242b,
                'card_background': #232932,
                'sidepanel': #21242b,
                'hover_list': #303d4f,
                'border': #7e8998,
                'panel': #31343b,
                'textColor': #dadada,
                'light_gray': #fff,
                'focus': #e7e9ed,
                'active_link': #fff,
                'labelColor': #f5f5f5,
                'modal': #21242b,
                'wizard_nav': #21242b,
                'wizard_nav_title': #f5f5f5,
                'wizard_content': #262626,
                'wizard_active_step': #0092e2,
                'card_header': #fff,
                'time_bg': #262626,
                'breadcrumb_item': #ababab,
                'datagrid_spinner': rgba(33, 36, 43, 0.6),
                'visited': #4dc2df,
                'placeholder': rgba(255, 255, 255, 0.54),
                'navHover': darken(#212121, 15%),
                'search-color': #52a9ff,
                'level-purple': #9d5973,
                'level-blue': #22acff,
                'level-green': #25ff33,
                'level-yellow': #ffff22,
                'level-red': #ff3523,
                'ql-editor-placeholder': rgba(255, 255, 225, 0.6),
                'milestone-color': #ffc107,
                'popover-shadow': rgba(134, 145, 181, 0.3),
                'note-shadow': rgba(255, 255, 255, 0.1),
                'note-shadow-hover': rgba(255, 255, 255, 0.05),
                'dg-selected-bg': #000,
                'dg-selected-text': #dadada,
                'dg-hover-bg': darken(#21252a, 8%),
                'dg-bg': #21252a,
                'dg-border': #dadada,
                'btn-secondary-border': #0092e0,
                'btn-secondary-color': #0092e0,
                'boardBg': linear-gradient(#21252f, #474747),
                'boardShadow': (
                    inset 0 0 2px 2px rgba(0, 0, 0, 0.3),
                    inset 0 30px 30px rgba(140, 140, 140, 0.1),
                ),
                'message-theirs': #171717,
                'message-mine': #006690,
                'border-light': unquote('#ffffff14'),
            )
        ),
);

@mixin keyMapValues($theme, $map) {
    @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
            $theme-map,
            (
                $key: $value,
            )
        ) !global;
    }
}

// stolen from: https://medium.com/@dmitriy.borodiy/easy-color-theming-with-scss-bc38fd5734d1
// prolly better: https://codepen.io/jakealbaugh/post/using-sass-functions-to-access-complex-variable-maps
@mixin themify() {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @include keyMapValues($theme, $map);
            @content;
            $theme-map: null !global;
        }

        // firefox fix, somehow :host-context(.theme-#{$theme}) &, .theme-#{$theme} & doesn't work
        :host-context(.theme-#{$theme}) & {
            $theme-map: () !global;
            @include keyMapValues($theme, $map);
            @content;
            $theme-map: null !global;
        }

        &.theme-#{$theme} {
            $theme-map: () !global;
            @include keyMapValues($theme, $map);
            @content;
            $theme-map: null !global;
        }
    }
}

@function theme($key) {
    @return map-get($theme-map, $key);
}

@function randomColor() {
    // FIXME get randomColor from theme
    @return map_get($randomColors, nth(map_keys($randomColors), random(length($randomColors))));
}
