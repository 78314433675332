@import '../themes/themes';

$SIDEBAR: (
    width: 240px,
    widthCollapsed: 50px,
    collapseTransition: max-width 0.4s ease\,
    min-width 0.4s ease,
    mediaQuery: 500px,
);

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.no-select {
    pointer-events: none;
    user-select: none;
}

.modal-body {
    max-height: 70vh !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0.125rem;
}

app-tabs-board {
    margin: -18px 0px 0 -21px;
    display: block;
}

// horsey class
.sey-container {
    z-index: 500 !important;
}

.Sidebar {
    font-size: 0.8rem;
    z-index: 500;
    position: fixed;
    min-width: map-get($SIDEBAR, width);
    // needs a max-width in order to overflow menu items
    max-width: map-get($SIDEBAR, width);
    min-height: 100%;
    transition: map-get($SIDEBAR, collapseTransition);
    user-select: none;

    @include themify() {
        background-color: theme('sidepanel');
        border-right: 1px solid theme('border');
    }

    a {
        text-decoration: none;
    }

    &.is-collapsed {
        max-width: 50px;
        min-width: 50px;
        transition: map-get($SIDEBAR, collapseTransition);

        .Sidebar-logo {
            padding: 0.6rem 0.4rem;
            transition: padding 0s;
        }

        .Sidebar-navItem {
            padding-left: 0;
            transition: padding-left 0.4s ease;
            a {
                padding-left: 0.85rem;
            }
        }

        .Sidebar-toggleText {
            display: none;
        }

        .Sidebar-toggleArrow {
            left: 17.5px;
            transform: rotate(179deg);
        }

        .Sidebar-footer {
            &:hover {
                .Sidebar-toggleArrow {
                    left: 17.5px;
                    transition: left 0.4s ease, transform 0.4s ease;
                }
            }
        }

        .Sidebar-menuIcon:not(.Sidebar-toggleArrow) {
            margin-left: -3px;
        }
    }

    @media (max-width: map-get($SIDEBAR, mediaQuery)) {
        min-width: map-get($SIDEBAR, widthCollapsed);
    }
}

app-board {
    .modal {
        *:not(button) {
            font-size: 0.7rem;
        }
    }
}

app-board-column {
    * {
        input {
            font-size: 0.7rem !important;
        }
    }
}

app-tabs-board {
    .modal {
        *:not(button) {
            font-size: 0.7rem;
        }
    }
}

.Sidebar-logo {
    padding: 1.3rem 3rem;
    transition: padding 0.4s ease;
    margin: 0.1rem;

    @media (max-height: 900px) {
        margin: 0;
        padding: 0.5rem 4rem;
    }

    & img {
        width: 100%;

        @media (max-width: map-get($SIDEBAR, mediaQUery)) {
            transform: translate(-24px, -80px);
            width: 50px;
            height: 25px;
        }
    }
}

@media (max-height: 690px) {
    app-wander {
        display: none;
    }
}

.Sidebar-footer {
    margin-bottom: 10vh;
    min-height: 45px;
    position: relative;
    width: 100%;
    @include themify() {
        color: theme('textColor');
    }

    @media (max-height: 690px) {
        margin-bottom: 0;
    }

    &:hover {
        cursor: pointer;

        @include themify() {
            background-color: theme('navHover');
        }

        .Sidebar-toggleArrow {
            transition: left 0.2s ease, rotate 1s ease;
            left: 50px;
        }

        @media (max-width: map-get($SIDEBAR, mediaQuery)) {
            &:hover {
                .Sidebar-toggleArrow {
                    left: 25px;
                }

                .Sidebar-toggleText {
                    display: none;
                }
            }
        }

        .Sidebar-toggleText {
            opacity: 1;
            animation: fadeIn 0.75s ease;
        }
    }
}

.Sidebar-toggleArrow {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-7px);
    cursor: pointer;
    transition: left 0.2s ease;

    @media (max-width: map-get($SIDEBAR, mediaQuery)) {
        left: 20px;
        transform: rotate(179deg);

        &:hover {
            left: 20px;
        }
    }
}

.Sidebar-toggleText {
    position: absolute;
    bottom: 13px;
    left: 85px;
    opacity: 0;
    font-size: 1rem;
    white-space: nowrap;
}

.Sidebar-menuIcon {
    width: 20px;

    &:not(.Sidebar-toggleArrow) {
        margin-right: 0.4rem;
    }
}

// -------------------------- TOASTS
$toast-height: 48px;
$toast-width: 245px;
$toast-bg: rgba(26, 26, 26, 0.89);
$toast-color-info: rgba(71, 195, 207, 0.9);
$toast-color-success: rgba(27, 190, 65, 0.9);
$toast-color-warning: rgba(246, 200, 0, 0.9);
$toast-color-error: rgba(219, 0, 4, 0.9);
$toast-color-shadow: 0 0 0.1rem 0.05rem;
.toast-container {
    z-index: 600;
    display: inline-grid;
    position: fixed;
    font-weight: bold;
    text-align: left;
    max-width: $toast-width;
    top: calc(50vh - (#{$toast-height} / 2));
    bottom: calc(50vh - (#{$toast-height} / 2));
    left: calc(50vw - (#{$toast-width} / 2));
    right: calc(50vw - (#{$toast-width} / 2));

    &.left {
        left: 0.4rem;
        right: unset;
    }

    &.top {
        top: 0.4rem;
        bottom: unset;
    }

    &.right {
        right: 0.4rem;
        left: unset;
    }

    &.bottom {
        bottom: 0.4rem;
        top: unset;
    }

    .toast-text {
        padding: 5px 0;
    }

    > .toast {
        position: relative;
        float: right;
        min-height: $toast-height;
        max-width: $toast-width;
        padding-right: 30px;
        font-size: 1.2em;
        opacity: 0;
        border: 0;
        transition: opacity 0.2s;

        &.info {
            background: $toast-bg;
            border-left: 5px solid $toast-color-info;
        }

        &.success {
            background: $toast-bg;
            border-left: 5px solid $toast-color-success;
        }

        &.warning {
            background: $toast-bg;
            border-left: 5px solid $toast-color-warning;
        }

        &.error {
            background: $toast-bg;
            border-left: 5px solid $toast-color-error;
        }

        &.active {
            opacity: 1;
            transition: opacity 0.2s, height 0.2s, margin-bottom 0.2s 0.2s, padding 0.2s 0.2s;

            &.inactive {
                opacity: 0;
                min-height: unset;
                margin-bottom: 0 !important;
                padding: 0;
            }
        }

        > button.btn.btn-clear {
            position: absolute;
            top: 6px;
            right: 6px;
        }
    }
}

// -------------------------------------------
// -------------------------- SPINNERS
.theme-dark .spinner {
    background: url('data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22Layer_2%22%20data-name%3D%22Layer%202%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%0A%20%20%20%20%3Cdefs%3E%0A%20%20%20%20%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-1%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%20none%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-miterlimit%3A%2010%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-width%3A%205px%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-opacity%3A%200.15%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke%3A%20%230079b8%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3C%2Fdefs%3E%0A%20%20%20%20%3Ctitle%3EPreloader_72x2%3C%2Ftitle%3E%0A%20%20%20%20%3Ccircle%20class%3D%22cls-1%22%20cx%3D%2236%22%20cy%3D%2236%22%20r%3D%2233%22%2F%3E%0A%20%20%20%20%3Cpath%20class%3D%22cls-2%22%20d%3D%22M14.3%2C60.9A33%2C33%2C0%2C0%2C1%2C36%2C3%22%3E%0A%20%20%20%20%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A');
}

.spinner-c {
    width: 80px;
    height: 80px;
    border: 2px solid #f3f3f3;
    border-top: 3px solid #27b6ba;
    border-radius: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.fade-transition {
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.spinner-bg-c {
    position: fixed;
    z-index: 500;
    //background: rgba(248, 249, 250, .75);
    height: 100vh;
    width: 100vw;
}

.spinner-bg-c-small {
    position: absolute;
    z-index: 500;
    // background: rgba(248, 249, 250, .75);
    height: 100%;
    width: 100%;
}

// -------------------------------------------
// -------------------------- TOOLTIP
.tooltip-fix::after {
    bottom: 50%;
    left: 100%;
    transform: translate(-0.2rem, 50%);
    min-width: 24rem;
}
.tooltip-custom {
    .tooltip {
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.85);
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        color: #fff;
        padding: 7px 10px;
        z-index: 1;

        .tooltip-inner {
            white-space: pre-wrap;
        }

        &::after {
            display: none;
        }
    }
}

// -------------------------------------------
// -------------------------- quill
quill-editor {
    strong {
        font-weight: bold;
    }
}

.ql-editor.ql-blank::before {
    @include themify() {
        color: theme('textColor');
    }
}
// -------------------------------------------
// -------------------------- Badge

.icon-with-badge {
    width: 1em;
    display: flex;
    clr-icon {
        min-width: 16px;
        min-height: 16px;
        opacity: 0.8;
    }
    span {
        position: relative;
        left: -0.7em;
        top: 0.5em;
        color: white !important;
    }
}

// -------------------------------------------
// -------------------------- Figure && avatar
figure {
    margin-right: 0.1em !important;

    clr-icon {
        width: 0.9rem;
        height: 0.9rem;
        vertical-align: top;
        text-align: center;
        margin-left: 0.15rem;
        margin-top: 0.15rem;
    }
}

app-long-avatar {
    clr-icon {
        width: 1rem;
        height: 1rem;
        vertical-align: top;
        text-align: center;
    }
}

.avatar {
    background: #5755d9;
    background-color: rgb(87, 85, 217);
    background-image: none;
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.85);
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 300;
    height: 1.2rem;
    line-height: 1.25;
    margin: 0;
    position: relative;
    vertical-align: middle;
    width: 1.2rem;
}
.avatar[data-initial]::before {
    color: currentcolor;
}
.avatar[data-initial]::before {
    color: currentColor;
    font-size: 0.6rem;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}
.avatar.smaller {
    font-size: 0.4rem;
    height: 1rem !important;
    width: 1rem !important;
    margin-top: 0.3em;
    &:before {
        font-size: 0.5rem;
    }
}
// -------------------------------------------
// -------------------------- Divider

.divider {
    min-height: 0.1rem;
}
.divider {
    border-top-color: #3f454e;
}
.divider {
    border-top: 0.05rem solid #e7e9ed;
    border-top-color: rgb(231, 233, 237);
    height: 0.05rem;
    margin: 0.4rem 0;
}
.divider,
.divider-vert {
    display: block;
    position: relative;
}

.divider-vert::before {
    min-width: 0.1rem;
}
.divider-vert::before {
    border-left-color: #3f454e;
}
.divider-vert::before {
    border-left: 0.05rem solid #e7e9ed;
    bottom: 0.4rem;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 0.4rem;
    transform: translateX(-50%);
}

// -------------------------------------------
// -------------------------- button

.btn.btn-sm {
    min-width: unset;
}

.btn.active:not(.signpost-trigger) {
    background-color: #0079b8;
    color: #fff;
}

// -------------------------------------------
// -------------------------- Label
.label {
    &.label-rounded {
        border-radius: 5rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    }
}

.small-label {
    margin-top: 0.2rem;
    font-size: 0.5rem;
    color: grey;
}

// -------------------------------------------
// -------------------------- Tile

.tile {
    blockquote {
        border-left: 0.1rem solid #0079b8;
        margin-left: 0;
        padding: 0.4rem 0.8rem;
    }

    blockquote p:last-child {
        margin-bottom: 0;
    }
    align-content: space-between;
    align-items: flex-start;
    display: flex;

    .tile-icon,
    .tile-action {
        flex: 0 0 auto;
    }
    .tile-content {
        flex: 1 1 auto;
        &:not(:first-child) {
            padding-left: 0.4rem;
        }
        &:not(:last-child) {
            padding-right: 0.4rem;
        }
    }
    .tile-title,
    .tile-subtitle {
        line-height: 1.6rem;
    }

    &.tile-centered {
        align-items: center;

        .tile-content {
            overflow: hidden;
        }

        .tile-title,
        .tile-subtitle {
            margin-bottom: 0;
        }
    }
}

// -------------------------------------------
// -------------------------- Popover

.popover .popover-container {
    width: unset;
}
.popover .popover-container {
    z-index: 1000;
}

.popover {
    display: inline-block;
    position: relative;

    .popover-container {
        left: 50%;
        opacity: 0;
        padding-top: 0;
        padding: 0 0.4rem 0.4rem 0.4rem;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.2s;
        width: 320px;
        z-index: 300;
    }

    *:focus + .popover-container,
    &:hover .popover-container {
        display: block;
        opacity: 1;
        transform: translate(-50%, -100%) scale(1);
    }

    &.popover-right {
        .popover-container {
            left: 100%;
            top: 50%;
        }

        *:focus + .popover-container,
        &:hover .popover-container {
            transform: translate(0, -50%) scale(1);
        }
    }

    &.popover-bottom {
        .popover-container {
            left: 50%;
            top: 100%;
        }

        *:focus + .popover-container,
        &:hover .popover-container {
            transform: translate(-50%, 0) scale(1);
        }
    }

    &.popover-left {
        .popover-container {
            left: 0;
            top: 50%;
        }

        *:focus + .popover-container,
        &:hover .popover-container {
            transform: translate(-100%, -50%) scale(1);
        }
    }

    .card {
        border: 0;
    }
}

.datagrid-host {
    .datagrid {
        .datagrid-header {
            position: sticky;
            top: 0;
            z-index: 200;
            min-height: 1.5rem;
            width: auto;
            /* fixes handle overflow over hidden columns */
            /* fucks with the filters */
            /* Waiting for https://github.com/vmware/clarity/issues/1248 (https://github.com/vmware/clarity/issues/2972) */
            /*overflow-x: hidden;*/
        }

        .datagrid-cell,
        .datagrid-column {
            font-size: 0.7rem;
            line-height: 0.7rem;
        }

        .badge {
            font-size: 0.45rem;
        }

        .datagrid-row .datagrid-action-overflow .action-item {
            font-size: 0.8rem;
        }

        .datagrid-row .datagrid-column:first-of-type clr-dg-filter {
            position: relative;
            .datagrid-filter {
                top: 12px !important;
                bottom: unset !important;
                left: 0 !important;
                right: auto !important;
                transform: unset !important;
                z-index: 1;
            }
        }
    }

    .datagrid-footer {
        font-size: 0.6rem;
        line-height: 1.5rem;
    }
}

app-gantt {
    .clr-form-control {
        margin-top: 0;
    }
    .signpost-content-header {
        display: block;
        padding-left: 1rem;
    }
}

.failed_review_reasons_ux {
    @include themify() {
        color: theme('level-yellow');
    }
}
.failed_review_reasons_ui {
    color: #24da94;
}
.failed_review_reasons_browser_compatibility {
    @include themify() {
        color: theme('level-purple');
    }
}
.failed_review_reasons_breaking {
    @include themify() {
        color: theme('level-red');
    }
}
.failed_review_reasons_regression {
    color: #ff0000;
}
.failed_review_reasons_detail {
    @include themify() {
        color: theme('level-blue');
    }
}
.failed_review_reasons_other {
    @include themify() {
        color: theme('level-green');
    }
}

app-report-tasks {
    clr-dg-filter {
        display: none !important;
    }

    .ng-select {
        width: 12rem;
        margin-top: -1rem;
    }
    .ng-select .ng-has-value .ng-placeholder,
    .ng-select.ng-select-opened .ng-placeholder {
        display: none !important;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
        padding-left: 0.1rem;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        margin-left: 0.2rem;

        @include themify() {
            border: 1px solid theme('dg-border');
        }
        border-radius: 5px;
        padding: 0 0.2rem;
    }
}

app-firon {
    .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value {
        margin-left: 0.2rem;

        @include themify() {
            border: 1px solid theme('dg-border');
        }
        border-radius: 5px;
        padding: 0 0.2rem;
    }
}

.clr-no-filter {
    * {
        clr-dg-filter {
            display: none !important;
        }
    }
}

app-rt-filter-date,
app-rt-filter-number {
    .clr-form-control {
        margin-top: 0 !important;
    }
}

app-todo {
    .clr-form-control {
        margin-top: 0rem !important;
    }
}

app-report-stats,
app-report-project,
app-report-client,
app-report-employee {
    .graph {
        font-size: 0.7rem !important;
    }

    .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
        font-size: 0.7rem !important;
    }

    .legend-item {
        line-height: 1.2rem !important;
    }
    .advanced-pie-legend {
        .total-value {
            line-height: 2rem;
        }
        .legend-items-container .legend-items {
            overflow-y: hidden;
            overflow-x: auto;
            padding-bottom: 1rem;
        }
    }
}

app-firon-detail-modal {
    .clr-wizard .clr-wizard-stepnav-link::before {
        display: none !important;
    }

    .clr-wizard .clr-wizard-stepnav-item {
        &.complete {
            box-shadow: 0.166667rem 0 0 #0079b8 inset !important;
            transition: box-shadow 0.2s ease-in;
        }
    }
}

app-project {
    form.clr-form-compact {
        input.clr-input,
        select.clr-select {
            position: relative;
            top: -2px;
        }
    }
}

.text-milestone-meeting {
    @include themify() {
        color: theme('milestone-meeting-color');
    }
}

.text-milestone-deadline {
    @include themify() {
        color: theme('milestone-deadline-color');
    }
}

.text-milestone-reminder {
    @include themify() {
        color: theme('milestone-reminder-color');
    }
}

.text-milestone-release {
    @include themify() {
        color: theme('milestone-release-color');
    }
}

.text-milestone-call {
    @include themify() {
        color: theme('milestone-call-color');
    }
}

* {
    scrollbar-width: thin;
}

app-project-budgets {
    .text-budget-estimated {
        @include themify() {
            color: theme('yellow');
        }
    }
    .text-budget-billed {
        @include themify() {
            color: theme('primary-color');
        }
    }
    .text-budget-cancelled {
        @include themify() {
            color: theme('red');
        }
    }
}

app-firon {
    .dropdown-menu {
        z-index: 150 !important;
    }
    .input-no-width {
        .clr-control-container {
            width: unset;
        }
    }
}

app-project-tasks,
app-firon,
app-dashboard-task,
app-gantt-task,
app-workload-release-modal {
    .text-task-status-backlog {
        @include themify() {
            color: theme('task-backlog-color');
        }
    }

    .text-task-status-todo {
        @include themify() {
            color: theme('task-todo-color');
        }
    }

    .text-task-status-inprogress {
        @include themify() {
            color: theme('task-inprogress-color');
        }
    }

    .text-task-status-review {
        @include themify() {
            color: theme('task-review-color');
        }
    }

    .text-task-status-reviewing {
        @include themify() {
            color: theme('task-reviewing-color');
        }
    }

    .text-task-status-done {
        font-weight: 400;
        @include themify() {
            color: theme('task-done-color');
        }
    }

    .text-progress-0 {
        @include themify() {
            color: theme('level-blue');
        }
    }
    .text-progress-10 {
        @include themify() {
            color: theme('level-green');
        }
    }
    .text-progress-40 {
        @include themify() {
            color: theme('level-yellow');
        }
    }
    .text-progress-80 {
        @include themify() {
            color: theme('level-red');
        }
    }

    .text-progress-overtime {
        @include themify() {
            color: theme('level-purple');
        }
    }
}

app-project clr-dg-row-detail {
    @include themify() {
        background-color: theme('main_background') !important;
    }
    .datagrid-cell,
    .datagrid-expandable-caret {
        @include themify() {
            background-color: theme('main_background') !important;
        }
    }
}

shared-generic-datagrid-filter {
    li {
        .clr-control-container {
            width: 100%;
        }

        span.badge {
            float: right;
            margin: 0.25rem 0 0 0.25rem;
        }
    }
}

clr-input-container .clr-control-container {
    width: 100%;
    input {
        width: 100%;
    }
}

app-edit-task {
    font-size: 0.7rem;
}

app-forms-add-task {
    * {
        .ng-input > input {
            height: 1.35rem;
        }
    }
    .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
        padding-bottom: 0;
        padding-top: 0;
    }
}

app-add-multiple-task {
    font-size: 0.6rem !important;

    li {
        list-style: disc !important;
        margin-left: 0.6rem;
    }

    .modal-header {
        > h3 {
            font-size: 0.8rem !important;
        }
    }
    * :not(button) {
        font-size: 0.6rem !important;
    }
    ng-dropdown-panel {
        min-width: 15rem !important;
    }
    .ng-option {
        padding-left: 0.4rem !important;
    }
    .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
        align-self: flex-end;
        bottom: 7px;
    }
    .ng-value {
        bottom: 7px;
        position: absolute;
        margin-top: 0;
    }

    .ng-select .ng-select-container .ng-value-container {
        align-items: baseline;
        padding: 0.4375em 0;
        border-top: 1rem solid transparent;
    }
}

app-forms-add-task,
app-edit-task {
    .sub-no-mt {
        > clr-date-container {
            margin-top: 0;
        }

        .clr-input-group {
            color: #000;
            display: inline-block;
            border-bottom: 1px solid #9a9a9a;
            background: linear-gradient(to bottom, transparent 95%, #0095d3 95%) no-repeat;
            background-size: auto;
            background-size: 0 100%;
            transition: background-size 0.2s ease;
            margin-right: 1rem;
            height: 22px;
        }
    }

    .ng-select .ng-select-container .ng-value-container .ng-input {
        bottom: -0.01rem;
        top: 0rem;

        > input {
            width: calc(100% - 0.5rem);
        }
    }

    .ng-select {
        input {
            margin-top: 0.2rem;
        }
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-top: 0rem;
        .ng-select-container {
            min-height: 1.5rem !important;
            align-items: baseline;
            .ng-value-container {
                align-items: baseline;
                padding: 0em 0;
                border-top: 0 solid transparent;
            }
        }
    }
}

app-modal clr-input-container .clr-control-container input {
    /* allow for error icon width */
    width: calc(100% - 20px);
}

.Main.is-collapsed {
    app-firon-detail-panel {
        .firon-fixed-sidebar {
            width: calc(25% - 10px);
        }
    }
}

clr-textarea-container .clr-control-container textarea {
    width: 100%;
}

shared-toast {
    z-index: 1060;
    position: absolute;
    top: 0;
    .toaster {
        margin-right: 0.4rem;
        max-width: 30vw;
        min-width: 15vw;
        position: fixed;
        top: 0;
        margin-top: 0.2rem;
        right: 0;
    }
    .top-toaster {
        width: 100vw;
        position: fixed;
        top: 0;
    }
    .alert-text {
        max-width: 30vw;
        min-width: 15vw;
    }
    .alert {
        overflow: hidden;
    }
}

form.clr-form-horizontal {
    clr-input-container,
    clr-select-container {
        .clr-control-label {
            line-height: 1rem;
        }
    }
}

.clr-wizard-footer-buttons clr-checkbox-wrapper .clr-control-label {
    line-height: 1.1rem;
}

.task-commits-container {
    .stack-view {
        -webkit-mask-image: unset;

        .stack-block {
            overflow: hidden;
            .stack-block-caret {
                float: left;
                margin-top: 5px;
            }
        }
    }

    .tooltip-sm:focus::after,
    .tooltip-sm:hover::after {
        -webkit-transform: translate(-120%, 1.2rem);
        transform: translate(-120%, 1.2rem);
    }
}

app-log-in {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100vw;
    min-height: 100vh;
}

app-sign-up {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100vw;
    min-height: 100vh;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

app-firon-helper {
    font-size: 0.7rem;
}

div.avatar-container {
    text-align: center;

    app-long-avatar {
        position: relative;
        font-size: 4em;
    }
}

td app-long-avatar .label {
    vertical-align: inherit;
}

app-long-avatar {
    &.add-emp .label {
        background-color: #0277bd !important;
        font-size: 1.2rem;
        font-weight: 400;
    }

    &.appelami .label {
        background-color: #e7621a !important;
    }
}

// Tooltips
.tooltip-sm {
    position: relative;
    &::after {
        background: rgba(rgba(69, 77, 93, 0.9), 0.95);
        border-radius: 0.1rem;
        bottom: 100%;
        color: #fff;
        content: attr(data-tooltip);
        display: block;
        font-size: 0.7rem;
        left: 50%;
        max-width: 320px;
        opacity: 0;
        overflow: hidden;
        padding: 0.2rem 0.4rem;
        pointer-events: none;
        position: absolute;
        text-overflow: ellipsis;
        transform: translate(-50%, 0.4rem);
        transition: opacity 0.2s, transform 0.2s;
        white-space: pre;
        z-index: 300;
    }
    &:focus,
    &:hover {
        &::after {
            opacity: 1;
            transform: translate(-50%, -0.2rem);
        }
    }
    &[disabled],
    &.disabled {
        pointer-events: auto;
    }

    &.tooltip-right {
        &::after {
            bottom: 50%;
            left: 100%;
            transform: translate(-0.2rem, 50%);
        }
        &:focus,
        &:hover {
            &::after {
                transform: translate(0.2rem, 50%);
            }
        }
    }

    &.tooltip-bottom {
        &::after {
            bottom: auto;
            top: 100%;
            transform: translate(-50%, -0.4rem);
        }
        &:focus,
        &:hover {
            &::after {
                transform: translate(-50%, 0.2rem);
            }
        }
    }

    &.tooltip-left {
        &::after {
            bottom: 50%;
            left: auto;
            right: 100%;
            transform: translate(0.4rem, 50%);
        }
        &:focus,
        &:hover {
            &::after {
                transform: translate(-0.2rem, 50%);
            }
        }
    }
}

.tooltip-sm-click {
    position: relative;
    &::after {
        background: rgba(rgba(69, 77, 93, 0.9), 0.95);
        border-radius: 0.1rem;
        bottom: 100%;
        color: #fff;
        content: attr(data-tooltip);
        display: block;
        font-size: 0.7rem;
        left: 50%;
        max-width: 320px;
        opacity: 0;
        overflow: hidden;
        padding: 0.2rem 0.4rem;
        pointer-events: none;
        position: absolute;
        text-overflow: ellipsis;
        transform: translate(-50%, 0.4rem);
        transition: opacity 0.2s, transform 0.2s;
        white-space: pre;
        z-index: 300;
    }
    &:focus,
    &.tp-active {
        &::after {
            opacity: 1;
            transform: translate(-50%, -0.2rem);
        }
    }
    &[disabled],
    &.disabled {
        pointer-events: auto;
    }

    &.tooltip-right {
        &::after {
            bottom: 50%;
            left: 100%;
            transform: translate(-0.2rem, 50%);
        }
        &:focus,
        &.tp-active {
            &::after {
                transform: translate(0.2rem, 50%);
            }
        }
    }

    &.tooltip-bottom {
        &::after {
            bottom: auto;
            top: 100%;
            transform: translate(-50%, -0.4rem);
        }
        &:focus,
        &.tp-active {
            &::after {
                transform: translate(-50%, 0.2rem);
            }
        }
    }

    &.tooltip-left {
        &::after {
            bottom: 50%;
            left: auto;
            right: 100%;
            transform: translate(0.4rem, 50%);
        }
        &:focus,
        &.tp-active {
            &::after {
                transform: translate(-0.2rem, 50%);
            }
        }
    }
}

[appClipboard] {
    textarea {
        // A NE PAS ENLEVER HEIN, ca fix le bulshit du mec qui a fait clipboard.js
        position: fixed !important;
        top: -15px !important;
        left: 0 !important;
        height: 1px !important;
        // width: 0 !important;
    }
}

kbd {
    background: #444444;
    border-radius: 0.1rem;
    color: #fff;
    font-size: 0.7rem;
    line-height: 1.2;
    padding: 0.1rem 0.2rem;
}

// Breadcrumbs

.breadcrumb .breadcrumb-item {
    color: rgb(238, 246, 251);
}
.breadcrumb .breadcrumb-item {
    color: #667189;
    display: inline-block;
    margin: 0;
    padding: 0.2rem 0;
}

.breadcrumb .breadcrumb-item:not(:first-child)::before {
    color: #969696;
    content: '/';
    padding: 0 0.3rem;
}

textarea {
    /* FIXME https://github.com/chrismsimpson/Metropolis/issues/15 Metropolis fucks with markdown code blocks */
    font-family: 'Avenir Next', 'Helvetica Neue', Arial, sans-serif;
}

.monospace,
.monospace-date {
    font-family: 'Source Code Pro', monospace;
}

.task-label {
    max-width: 10rem;
    line-height: 0.5rem;
}

table.info-table {
    font-size: 0.7rem;
    border-spacing: 0.5rem 0;

    tr {
        td {
            border-right: 1px solid #b7b7b7;
            padding-right: 0.5rem;
        }
        td:last-of-type,
        td:first-of-type {
            border-right: none;
        }
    }
}

.modal-title {
    word-break: break-all;
}

.Sidebar-navItem {
    white-space: nowrap;
    overflow: hidden;
    transition: padding-left 0.4s ease;
    position: relative;
    margin-top: 0;

    @media (max-width: map-get($SIDEBAR, mediaQUery)) {
        padding-left: 17px;
    }

    &.active {
        @include themify() {
            background-color: theme('base');
        }
    }

    &:hover {
        @include themify() {
            background-color: theme('navHover');
        }
    }

    &:not([data-tab='timer']):hover {
        cursor: pointer;
    }

    a {
        outline: 0;
        opacity: 1;
        transition: opacity 0.4s ease;
        padding: 0.5rem 0 0.5rem 25px;
        display: block;
        @include themify() {
            color: theme('textColor') !important;
        }

        @media (max-height: 900px) {
            padding-top: 0.35rem;
            padding-bottom: 0.35rem;
        }

        &:focus {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        @media (max-width: map-get($SIDEBAR, mediaQUery)) {
            display: none;
        }
    }
}

// prismjs
code.language-inline-diff,
pre.language-inline-diff {
    .token {
        &.deletions {
            color: #f52f22;
        }
        &.insertions {
            color: #48960c;
        }
    }
}

.card-footer clr-icon {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

.firon-fixed-sidebar {
    .card-block,
    .card-footer,
    .card-header,
    .card > .list,
    .card > .list-unstyled {
        padding: 0.2rem 0.3rem;
    }
    .card-block .btn,
    .card-block .btn.btn-link,
    .card-block .card-link,
    .card-footer .btn,
    .card-footer .btn.btn-link,
    .card-footer .card-link {
        margin: 0 0.3rem 0 0;
    }
    .card-footer clr-icon {
        margin-top: -0.25rem;
        margin-bottom: -0.25rem;
    }
    form {
        padding-top: 0.2rem;
    }
    .card {
        margin-top: 0.5rem;
    }

    .modal {
        .card-block,
        .card-footer,
        .card-header,
        .card > .list,
        .card > .list-unstyled {
            padding: 0.5rem 0.75rem;
        }
        .card-footer clr-icon {
            margin-top: -0.5rem;
            margin-bottom: -0.5rem;
        }
        form {
            padding-top: 0.5rem;
        }
        .card {
            margin-top: 1rem;
        }
    }
}

.modal-footer .btn-footer-left {
    margin-left: 0;
    margin-right: auto;
}

.clr-wizard .modal-dialog .modal-dialog {
    height: unset;

    .modal-content {
        width: 100%;
        flex: unset;
        padding: 1rem;

        .modal-header {
            padding: 0 0 1rem;
        }

        .modal-footer {
            display: flex;
            flex: unset;
            height: unset;
            min-height: unset;
        }
    }
}

.legend-label {
    list-style: none !important;
}

.card-text {
    ul {
        margin-left: 1rem;
        li {
            list-style: disc;
            padding: 0.2rem 0;
        }
    }
    p {
        margin-top: 0;

        &:empty {
            display: none;
        }
    }
}

.spinner-container {
    margin-top: 50vh;
    text-align: center;
    font-size: 2rem;
}

.clr-input-group {
    // set same height as .clr-input-wrapper
    height: 1rem;
}

clr-textarea-container.p-0 .clr-control-container {
    padding: 0;
}

clr-signpost {
    button.signpost-action {
        height: 1rem;
        line-height: 100%;
    }

    clr-signpost-content {
        cursor: default;

        .signpost-content-header .signpost-action.close clr-icon {
            margin-top: -1rem;
        }
        .signpost-content-body {
            padding: 0 0.5rem 0.5rem;
        }
    }

    .signpost-flex-wrap {
        @include themify() {
            background-color: theme('main_background');
            color: theme('textColor');
        }
    }

    .signpost-content.bottom-left .popover-pointer:before,
    .signpost-content.bottom-middle .popover-pointer:before,
    .signpost-content.bottom-right .popover-pointer:before {
        @include themify() {
            border-bottom-color: theme('main_background');
        }
    }
}

// -------------------------- STATUS
.status-backlog {
    @include themify() {
        color: theme('task-backlog-color');
    }
}

.status-todo {
    @include themify() {
        color: theme('task-todo-color');
    }
}

.status-inprogress {
    @include themify() {
        color: theme('task-inprogress-color');
    }
}

.status-review {
    @include themify() {
        color: theme('task-review-color');
    }
}

.status-reviewing {
    @include themify() {
        color: theme('task-reviewing-color');
    }
}

.status-done {
    font-weight: 400;
    @include themify() {
        color: theme('task-done-color');
    }
}

.text-light {
    color: #fff !important;
}
.text-dark {
    color: #3b4351 !important;
}
.text-error {
    color: #e53935 !important;
}
.text-blue {
    color: #0095d3;
}

// -------------------------- THEME DARK
.close {
    clr-icon {
        @include themify() {
            fill: theme('light_gray');
        }
    }
}
clr-alert .alert-app-level .close clr-icon {
    fill: #fff !important;
}

.close:focus,
.clr-checkbox-wrapper input[type='checkbox']:focus + label::before {
    @include themify() {
        box-shadow: 0 0 2px 2px theme('focus');
    }
}

.modal-dialog .modal-content {
    @include themify() {
        background-color: theme('modal');
    }
}
.modal-header,
.modal-header .modal-title {
    @include themify() {
        color: theme('textColor');
    }
}
.modal-body {
    @include themify() {
        color: theme('textColor');
    }
}

.clr-control-label {
    @include themify() {
        color: theme('labelColor');
    }
}

.clr-multiselect-wrapper select,
.clr-select-wrapper select {
    @include themify() {
        color: theme('textColor');
    }
}

input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'] {
    @include themify() {
        color: theme('inverse');
    }
}

.ng-select .ng-select-container {
    .ng-value {
        @include themify() {
            color: theme('textColor') !important;
        }
        shared-editable-employee-label .label,
        shared-task-tag .label {
            @include themify() {
                color: theme('textColor');
            }
        }
    }
    .ng-value-container .ng-placeholder,
    .ng-clear-wrapper,
    .ng-arrow-wrapper .ng-arrow {
        @include themify() {
            color: theme('placeholder');
        }
    }
    &:after {
        @include themify() {
            border-color: theme('placeholder');
        }
    }
}

.clr-textarea,
textarea {
    @include themify() {
        background-color: theme('modal');
        color: theme('textColor');
    }
}

select[multiple],
select[size] {
    @include themify() {
        background-color: theme('modal');
    }
    option {
        @include themify() {
            color: theme('textColor');
        }
    }
}

.card,
.card .list-group-item {
    @include themify() {
        background-color: theme('card_background');
        border-color: theme('border');
    }
}
.card-header,
.card-title {
    @include themify() {
        color: theme('inverse');
    }
}

.card-block,
.card-header {
    border-bottom: 1px solid;
    @include themify() {
        border-bottom-color: theme('border');
    }
}
.datagrid,
.datagrid-header .datagrid-row,
.datagrid .datagrid-column,
.datagrid-table .datagrid-column .datagrid-column-title,
.datagrid-footer,
.datagrid-table .datagrid-column .datagrid-filter,
.datagrid-table .datagrid-placeholder,
.datagrid-footer .column-switch-wrapper .column-switch {
    @include themify() {
        background-color: theme('dg-bg');
        color: theme('textColor');
        border-color: theme('dg-border');
    }
}

.datagrid-row:hover {
    @include themify() {
        background-color: theme('dg-hover-bg');
    }
}

.datagrid-row.datagrid-selected {
    @include themify() {
        background-color: theme('dg-selected-bg');
        color: theme('dg-selected-text');
    }
}

.datagrid-spinner {
    @include themify() {
        background-color: theme('datagrid_spinner');
    }
}

.table {
    @include themify() {
        color: theme('textColor');
        background-color: theme('main_background');
    }
    th {
        @include themify() {
            background-color: theme('dg-bg');
            color: theme('textColor');
        }
    }
}

.dropdown-menu {
    @include themify() {
        background-color: theme('modal');
    }
    .btn,
    .btn-danger,
    .btn-info,
    .btn-link,
    .btn-outline,
    .btn-outline-danger,
    .btn-outline-primary,
    .btn-outline-secondary,
    .btn-outline-success,
    .btn-outline-warning,
    .btn-primary,
    .btn-secondary,
    .btn-success,
    .btn-warning,
    .dropdown-item {
        @include themify() {
            color: theme('textColor');
        }
    }
    .btn-danger:focus,
    .btn-danger:hover,
    .btn-info:focus,
    .btn-info:hover,
    .btn-link:focus,
    .btn-link:hover,
    .btn-outline-danger:focus,
    .btn-outline-danger:hover,
    .btn-outline-primary:focus,
    .btn-outline-primary:hover,
    .btn-outline-secondary:focus,
    .btn-outline-secondary:hover,
    .btn-outline-success:focus,
    .btn-outline-success:hover,
    .btn-outline-warning:focus,
    .btn-outline-warning:hover,
    .btn-outline:focus,
    .btn-outline:hover,
    .btn-primary:focus,
    .btn-primary:hover,
    .btn-secondary:focus,
    .btn-secondary:hover,
    .btn-success:focus,
    .btn-success:hover,
    .btn-warning:focus,
    .btn-warning:hover,
    .btn:focus,
    .btn:hover,
    .dropdown-item:focus,
    .dropdown-item:hover {
        @include themify() {
            background-color: theme('hover_list');
        }
    }
}

.clr-input {
    @include themify() {
        color: theme('inverse');
    }
}

clr-control-helper.clr-subtext {
    @include themify() {
        color: theme('light_gray');
    }
}

clr-wizard.clr-wizard {
    .clr-wizard-stepnav-wrapper {
        @include themify() {
            background-color: theme('wizard_nav');
        }
    }
    .clr-wizard-title {
        @include themify() {
            color: theme('wizard_nav_title');
        }
    }

    .clr-wizard-stepnav-item {
        &:not(.disabled) {
            @include themify() {
                color: theme('textColor');
            }
        }
        &.active {
            @include themify() {
                color: theme('wizard_nav_title');
            }

            & .clr-wizard-stepnav-link {
                @include themify() {
                    background-color: theme('wizard_active_step');
                }
            }
        }
    }
}

.dropdown-menu .dropdown-header {
    @include themify() {
        color: theme('wizard_nav_title');
    }
}

.clr-checkbox-wrapper label,
.clr-radio-wrapper label {
    @include themify() {
        color: theme('textColor');
    }
}

clr-stack-view .stack-view {
    @include themify() {
        color: theme('textColor');
        background-color: theme('main_background');
    }

    .stack-block-content,
    .stack-block-label {
        @include themify() {
            color: theme('textColor');
            background-color: theme('wizard_nav');
        }
    }

    .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-content,
    .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-label,
    .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-content,
    .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-label {
        @include themify() {
            background-color: theme('sidepanel');
        }
    }
}

a:visited {
    @include themify() {
        color: theme('visited');
    }
}

.clr-col-no-basis {
    flex-basis: unset;
    width: 0;
}

.btn-info .btn,
.btn-outline-info .btn,
.btn-outline-primary .btn,
.btn-outline-secondary .btn,
.btn-primary-outline .btn,
.btn-secondary .btn,
.btn-secondary-outline .btn,
.btn.btn-info,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-primary-outline,
.btn.btn-secondary,
.btn.btn-secondary-outline {
    @include themify() {
        color: theme('btn-secondary-color');
        border-color: theme('btn-secondary-border');
    }
}
.btn.active {
    color: #fff !important;
}

optgroup {
    color: #21242b;
}

app-projects-list .datagrid-table clr-signpost clr-icon {
    @include themify() {
        color: theme('textColor');
    }
}

.datagrid-footer .column-switch-wrapper .column-switch .switch-footer .btn {
    @include themify() {
        color: theme('textColor');
    }
}

app-board-detail-container {
    p {
        font-size: 0.7rem !important;
    }
}

app-board-detail-main {
    .clr-control-container {
        width: 100%;
    }
}

/* .dg-drag { 
    //Class appears on element when its draggable
} */

.dg-wrapper {
    position: static;
    width: 0;
    height: 0;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
}

.dg-controls {
    display: inline-block;
    box-sizing: border-box;
    top: 0;
    left: 0;
    z-index: 190;
    border: 1px dashed #32b5fe;
    cursor: move;
}

.dg-hdl {
    cursor: pointer;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: white;
    border: 1px solid #32b5fe;
    margin-top: -6px;
    margin-left: -6px;
}

.dg,
.dg-controls,
.dg-hdl {
    position: absolute;
    z-index: 199;
}

.dg-hdl-t {
    top: 0;
}

.dg-hdl-m {
    top: 50%;
}

.dg-hdl-b {
    top: calc(100% + 5px) !important;
}

.dg-hdl-l {
    left: -5px;
}

.dg-hdl-c {
    left: 50%;
    top: -5px;
}

.dg-hdl-r {
    left: calc(100% + 5px);
}

.dg-rotator {
    top: -25px;
    left: 50%;
}

.comment-content {
    p {
        margin-top: 0;
    }
}
