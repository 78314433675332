@import url(../node_modules/fullcalendar/dist/fullcalendar.min.css);
@import url(~@ng-select/ng-select/themes/material.theme.css);
.gantt_layout_content {
  min-height: 50vh; }

.gantt_grid_head_cell.gantt_grid_head_add {
  display: none; }

.no_drag_progress .gantt_task_progress_drag {
  display: none !important; }

.gantt_task_row.gantt_selected .gantt_task_cell {
  border-right-color: #5dcfe3; }

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected {
  background-color: #5dcfe3; }

.gantt_grid_data .gantt_row:hover,
.gantt_grid_data .gantt_row.odd:hover {
  background-color: #5dcfe3; }

.gantt_task_row.gantt_selected {
  background-color: #5dcfe3; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.no-select {
  pointer-events: none;
  user-select: none; }

.modal-body {
  max-height: 70vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0.125rem; }

app-tabs-board {
  margin: -18px 0px 0 -21px;
  display: block; }

.sey-container {
  z-index: 500 !important; }

.Sidebar {
  font-size: 0.8rem;
  z-index: 500;
  position: fixed;
  min-width: 240px;
  max-width: 240px;
  min-height: 100%;
  transition: max-width 0.4s ease\, min-width 0.4s ease;
  user-select: none; }
  .theme-light .Sidebar {
    background-color: #eee;
    border-right: 1px solid #a8a8a8; }
  :host-context(.theme-light) .Sidebar {
    background-color: #eee;
    border-right: 1px solid #a8a8a8; }
  .Sidebar.theme-light {
    background-color: #eee;
    border-right: 1px solid #a8a8a8; }
  .theme-dark .Sidebar {
    background-color: #21242b;
    border-right: 1px solid #7e8998; }
  :host-context(.theme-dark) .Sidebar {
    background-color: #21242b;
    border-right: 1px solid #7e8998; }
  .Sidebar.theme-dark {
    background-color: #21242b;
    border-right: 1px solid #7e8998; }
  .Sidebar a {
    text-decoration: none; }
  .Sidebar.is-collapsed {
    max-width: 50px;
    min-width: 50px;
    transition: max-width 0.4s ease\, min-width 0.4s ease; }
    .Sidebar.is-collapsed .Sidebar-logo {
      padding: 0.6rem 0.4rem;
      transition: padding 0s; }
    .Sidebar.is-collapsed .Sidebar-navItem {
      padding-left: 0;
      transition: padding-left 0.4s ease; }
      .Sidebar.is-collapsed .Sidebar-navItem a {
        padding-left: 0.85rem; }
    .Sidebar.is-collapsed .Sidebar-toggleText {
      display: none; }
    .Sidebar.is-collapsed .Sidebar-toggleArrow {
      left: 17.5px;
      transform: rotate(179deg); }
    .Sidebar.is-collapsed .Sidebar-footer:hover .Sidebar-toggleArrow {
      left: 17.5px;
      transition: left 0.4s ease, transform 0.4s ease; }
    .Sidebar.is-collapsed .Sidebar-menuIcon:not(.Sidebar-toggleArrow) {
      margin-left: -3px; }
  @media (max-width: 500px) {
    .Sidebar {
      min-width: 50px; } }

app-board .modal *:not(button) {
  font-size: 0.7rem; }

app-board-column * input {
  font-size: 0.7rem !important; }

app-tabs-board .modal *:not(button) {
  font-size: 0.7rem; }

.Sidebar-logo {
  padding: 1.3rem 3rem;
  transition: padding 0.4s ease;
  margin: 0.1rem; }
  @media (max-height: 900px) {
    .Sidebar-logo {
      margin: 0;
      padding: 0.5rem 4rem; } }
  .Sidebar-logo img {
    width: 100%; }
    @media (max-width: null) {
      .Sidebar-logo img {
        transform: translate(-24px, -80px);
        width: 50px;
        height: 25px; } }

@media (max-height: 690px) {
  app-wander {
    display: none; } }

.Sidebar-footer {
  margin-bottom: 10vh;
  min-height: 45px;
  position: relative;
  width: 100%; }
  .theme-light .Sidebar-footer {
    color: #565656; }
  :host-context(.theme-light) .Sidebar-footer {
    color: #565656; }
  .Sidebar-footer.theme-light {
    color: #565656; }
  .theme-dark .Sidebar-footer {
    color: #dadada; }
  :host-context(.theme-dark) .Sidebar-footer {
    color: #dadada; }
  .Sidebar-footer.theme-dark {
    color: #dadada; }
  @media (max-height: 690px) {
    .Sidebar-footer {
      margin-bottom: 0; } }
  .Sidebar-footer:hover {
    cursor: pointer; }
    .theme-light .Sidebar-footer:hover {
      background-color: #ccc; }
    :host-context(.theme-light) .Sidebar-footer:hover {
      background-color: #ccc; }
    .Sidebar-footer:hover.theme-light {
      background-color: #ccc; }
    .theme-dark .Sidebar-footer:hover {
      background-color: black; }
    :host-context(.theme-dark) .Sidebar-footer:hover {
      background-color: black; }
    .Sidebar-footer:hover.theme-dark {
      background-color: black; }
    .Sidebar-footer:hover .Sidebar-toggleArrow {
      transition: left 0.2s ease, rotate 1s ease;
      left: 50px; }
    @media (max-width: 500px) {
      .Sidebar-footer:hover:hover .Sidebar-toggleArrow {
        left: 25px; }
      .Sidebar-footer:hover:hover .Sidebar-toggleText {
        display: none; } }
    .Sidebar-footer:hover .Sidebar-toggleText {
      opacity: 1;
      animation: fadeIn 0.75s ease; }

.Sidebar-toggleArrow {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-7px);
  cursor: pointer;
  transition: left 0.2s ease; }
  @media (max-width: 500px) {
    .Sidebar-toggleArrow {
      left: 20px;
      transform: rotate(179deg); }
      .Sidebar-toggleArrow:hover {
        left: 20px; } }

.Sidebar-toggleText {
  position: absolute;
  bottom: 13px;
  left: 85px;
  opacity: 0;
  font-size: 1rem;
  white-space: nowrap; }

.Sidebar-menuIcon {
  width: 20px; }
  .Sidebar-menuIcon:not(.Sidebar-toggleArrow) {
    margin-right: 0.4rem; }

.toast-container {
  z-index: 600;
  display: inline-grid;
  position: fixed;
  font-weight: bold;
  text-align: left;
  max-width: 245px;
  top: calc(50vh - (48px / 2));
  bottom: calc(50vh - (48px / 2));
  left: calc(50vw - (245px / 2));
  right: calc(50vw - (245px / 2)); }
  .toast-container.left {
    left: 0.4rem;
    right: unset; }
  .toast-container.top {
    top: 0.4rem;
    bottom: unset; }
  .toast-container.right {
    right: 0.4rem;
    left: unset; }
  .toast-container.bottom {
    bottom: 0.4rem;
    top: unset; }
  .toast-container .toast-text {
    padding: 5px 0; }
  .toast-container > .toast {
    position: relative;
    float: right;
    min-height: 48px;
    max-width: 245px;
    padding-right: 30px;
    font-size: 1.2em;
    opacity: 0;
    border: 0;
    transition: opacity 0.2s; }
    .toast-container > .toast.info {
      background: rgba(26, 26, 26, 0.89);
      border-left: 5px solid rgba(71, 195, 207, 0.9); }
    .toast-container > .toast.success {
      background: rgba(26, 26, 26, 0.89);
      border-left: 5px solid rgba(27, 190, 65, 0.9); }
    .toast-container > .toast.warning {
      background: rgba(26, 26, 26, 0.89);
      border-left: 5px solid rgba(246, 200, 0, 0.9); }
    .toast-container > .toast.error {
      background: rgba(26, 26, 26, 0.89);
      border-left: 5px solid rgba(219, 0, 4, 0.9); }
    .toast-container > .toast.active {
      opacity: 1;
      transition: opacity 0.2s, height 0.2s, margin-bottom 0.2s 0.2s, padding 0.2s 0.2s; }
      .toast-container > .toast.active.inactive {
        opacity: 0;
        min-height: unset;
        margin-bottom: 0 !important;
        padding: 0; }
    .toast-container > .toast > button.btn.btn-clear {
      position: absolute;
      top: 6px;
      right: 6px; }

.theme-dark .spinner {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22Layer_2%22%20data-name%3D%22Layer%202%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%0A%20%20%20%20%3Cdefs%3E%0A%20%20%20%20%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-1%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%20none%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-miterlimit%3A%2010%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-width%3A%205px%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-opacity%3A%200.15%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke%3A%20%230079b8%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3C%2Fdefs%3E%0A%20%20%20%20%3Ctitle%3EPreloader_72x2%3C%2Ftitle%3E%0A%20%20%20%20%3Ccircle%20class%3D%22cls-1%22%20cx%3D%2236%22%20cy%3D%2236%22%20r%3D%2233%22%2F%3E%0A%20%20%20%20%3Cpath%20class%3D%22cls-2%22%20d%3D%22M14.3%2C60.9A33%2C33%2C0%2C0%2C1%2C36%2C3%22%3E%0A%20%20%20%20%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A"); }

.spinner-c {
  width: 80px;
  height: 80px;
  border: 2px solid #f3f3f3;
  border-top: 3px solid #27b6ba;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: spin 1s infinite linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.fade-transition {
  animation: fadein 2s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.spinner-bg-c {
  position: fixed;
  z-index: 500;
  height: 100vh;
  width: 100vw; }

.spinner-bg-c-small {
  position: absolute;
  z-index: 500;
  height: 100%;
  width: 100%; }

.tooltip-fix::after {
  bottom: 50%;
  left: 100%;
  transform: translate(-0.2rem, 50%);
  min-width: 24rem; }

.tooltip-custom .tooltip {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 7px 10px;
  z-index: 1; }
  .tooltip-custom .tooltip .tooltip-inner {
    white-space: pre-wrap; }
  .tooltip-custom .tooltip::after {
    display: none; }

quill-editor strong {
  font-weight: bold; }

.theme-light .ql-editor.ql-blank::before {
  color: #565656; }

:host-context(.theme-light) .ql-editor.ql-blank::before {
  color: #565656; }

.ql-editor.ql-blank::before.theme-light {
  color: #565656; }

.theme-dark .ql-editor.ql-blank::before {
  color: #dadada; }

:host-context(.theme-dark) .ql-editor.ql-blank::before {
  color: #dadada; }

.ql-editor.ql-blank::before.theme-dark {
  color: #dadada; }

.icon-with-badge {
  width: 1em;
  display: flex; }
  .icon-with-badge clr-icon {
    min-width: 16px;
    min-height: 16px;
    opacity: 0.8; }
  .icon-with-badge span {
    position: relative;
    left: -0.7em;
    top: 0.5em;
    color: white !important; }

figure {
  margin-right: 0.1em !important; }
  figure clr-icon {
    width: 0.9rem;
    height: 0.9rem;
    vertical-align: top;
    text-align: center;
    margin-left: 0.15rem;
    margin-top: 0.15rem; }

app-long-avatar clr-icon {
  width: 1rem;
  height: 1rem;
  vertical-align: top;
  text-align: center; }

.avatar {
  background: #5755d9;
  background-color: #5755d9;
  background-image: none;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.85);
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 300;
  height: 1.2rem;
  line-height: 1.25;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 1.2rem; }

.avatar[data-initial]::before {
  color: currentcolor; }

.avatar[data-initial]::before {
  color: currentColor;
  font-size: 0.6rem;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0; }

.avatar.smaller {
  font-size: 0.4rem;
  height: 1rem !important;
  width: 1rem !important;
  margin-top: 0.3em; }
  .avatar.smaller:before {
    font-size: 0.5rem; }

.divider {
  min-height: 0.1rem; }

.divider {
  border-top-color: #3f454e; }

.divider {
  border-top: 0.05rem solid #e7e9ed;
  border-top-color: #e7e9ed;
  height: 0.05rem;
  margin: 0.4rem 0; }

.divider,
.divider-vert {
  display: block;
  position: relative; }

.divider-vert::before {
  min-width: 0.1rem; }

.divider-vert::before {
  border-left-color: #3f454e; }

.divider-vert::before {
  border-left: 0.05rem solid #e7e9ed;
  bottom: 0.4rem;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  top: 0.4rem;
  transform: translateX(-50%); }

.btn.btn-sm {
  min-width: unset; }

.btn.active:not(.signpost-trigger) {
  background-color: #0079b8;
  color: #fff; }

.label.label-rounded {
  border-radius: 5rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem; }

.small-label {
  margin-top: 0.2rem;
  font-size: 0.5rem;
  color: grey; }

.tile {
  align-content: space-between;
  align-items: flex-start;
  display: flex; }
  .tile blockquote {
    border-left: 0.1rem solid #0079b8;
    margin-left: 0;
    padding: 0.4rem 0.8rem; }
  .tile blockquote p:last-child {
    margin-bottom: 0; }
  .tile .tile-icon,
  .tile .tile-action {
    flex: 0 0 auto; }
  .tile .tile-content {
    flex: 1 1 auto; }
    .tile .tile-content:not(:first-child) {
      padding-left: 0.4rem; }
    .tile .tile-content:not(:last-child) {
      padding-right: 0.4rem; }
  .tile .tile-title,
  .tile .tile-subtitle {
    line-height: 1.6rem; }
  .tile.tile-centered {
    align-items: center; }
    .tile.tile-centered .tile-content {
      overflow: hidden; }
    .tile.tile-centered .tile-title,
    .tile.tile-centered .tile-subtitle {
      margin-bottom: 0; }

.popover .popover-container {
  width: unset; }

.popover .popover-container {
  z-index: 1000; }

.popover {
  display: inline-block;
  position: relative; }
  .popover .popover-container {
    left: 50%;
    opacity: 0;
    padding-top: 0;
    padding: 0 0.4rem 0.4rem 0.4rem;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s;
    width: 320px;
    z-index: 300; }
  .popover *:focus + .popover-container,
  .popover:hover .popover-container {
    display: block;
    opacity: 1;
    transform: translate(-50%, -100%) scale(1); }
  .popover.popover-right .popover-container {
    left: 100%;
    top: 50%; }
  .popover.popover-right *:focus + .popover-container,
  .popover.popover-right:hover .popover-container {
    transform: translate(0, -50%) scale(1); }
  .popover.popover-bottom .popover-container {
    left: 50%;
    top: 100%; }
  .popover.popover-bottom *:focus + .popover-container,
  .popover.popover-bottom:hover .popover-container {
    transform: translate(-50%, 0) scale(1); }
  .popover.popover-left .popover-container {
    left: 0;
    top: 50%; }
  .popover.popover-left *:focus + .popover-container,
  .popover.popover-left:hover .popover-container {
    transform: translate(-100%, -50%) scale(1); }
  .popover .card {
    border: 0; }

.datagrid-host .datagrid .datagrid-header {
  position: sticky;
  top: 0;
  z-index: 200;
  min-height: 1.5rem;
  width: auto;
  /* fixes handle overflow over hidden columns */
  /* fucks with the filters */
  /* Waiting for https://github.com/vmware/clarity/issues/1248 (https://github.com/vmware/clarity/issues/2972) */
  /*overflow-x: hidden;*/ }

.datagrid-host .datagrid .datagrid-cell,
.datagrid-host .datagrid .datagrid-column {
  font-size: 0.7rem;
  line-height: 0.7rem; }

.datagrid-host .datagrid .badge {
  font-size: 0.45rem; }

.datagrid-host .datagrid .datagrid-row .datagrid-action-overflow .action-item {
  font-size: 0.8rem; }

.datagrid-host .datagrid .datagrid-row .datagrid-column:first-of-type clr-dg-filter {
  position: relative; }
  .datagrid-host .datagrid .datagrid-row .datagrid-column:first-of-type clr-dg-filter .datagrid-filter {
    top: 12px !important;
    bottom: unset !important;
    left: 0 !important;
    right: auto !important;
    transform: unset !important;
    z-index: 1; }

.datagrid-host .datagrid-footer {
  font-size: 0.6rem;
  line-height: 1.5rem; }

app-gantt .clr-form-control {
  margin-top: 0; }

app-gantt .signpost-content-header {
  display: block;
  padding-left: 1rem; }

.theme-light .failed_review_reasons_ux {
  color: #7a7f33; }

:host-context(.theme-light) .failed_review_reasons_ux {
  color: #7a7f33; }

.failed_review_reasons_ux.theme-light {
  color: #7a7f33; }

.theme-dark .failed_review_reasons_ux {
  color: #ffff22; }

:host-context(.theme-dark) .failed_review_reasons_ux {
  color: #ffff22; }

.failed_review_reasons_ux.theme-dark {
  color: #ffff22; }

.failed_review_reasons_ui {
  color: #24da94; }

.theme-light .failed_review_reasons_browser_compatibility {
  color: #9d5973; }

:host-context(.theme-light) .failed_review_reasons_browser_compatibility {
  color: #9d5973; }

.failed_review_reasons_browser_compatibility.theme-light {
  color: #9d5973; }

.theme-dark .failed_review_reasons_browser_compatibility {
  color: #9d5973; }

:host-context(.theme-dark) .failed_review_reasons_browser_compatibility {
  color: #9d5973; }

.failed_review_reasons_browser_compatibility.theme-dark {
  color: #9d5973; }

.theme-light .failed_review_reasons_breaking {
  color: #ff3523; }

:host-context(.theme-light) .failed_review_reasons_breaking {
  color: #ff3523; }

.failed_review_reasons_breaking.theme-light {
  color: #ff3523; }

.theme-dark .failed_review_reasons_breaking {
  color: #ff3523; }

:host-context(.theme-dark) .failed_review_reasons_breaking {
  color: #ff3523; }

.failed_review_reasons_breaking.theme-dark {
  color: #ff3523; }

.failed_review_reasons_regression {
  color: #ff0000; }

.theme-light .failed_review_reasons_detail {
  color: #1963ff; }

:host-context(.theme-light) .failed_review_reasons_detail {
  color: #1963ff; }

.failed_review_reasons_detail.theme-light {
  color: #1963ff; }

.theme-dark .failed_review_reasons_detail {
  color: #22acff; }

:host-context(.theme-dark) .failed_review_reasons_detail {
  color: #22acff; }

.failed_review_reasons_detail.theme-dark {
  color: #22acff; }

.theme-light .failed_review_reasons_other {
  color: #37a93d; }

:host-context(.theme-light) .failed_review_reasons_other {
  color: #37a93d; }

.failed_review_reasons_other.theme-light {
  color: #37a93d; }

.theme-dark .failed_review_reasons_other {
  color: #25ff33; }

:host-context(.theme-dark) .failed_review_reasons_other {
  color: #25ff33; }

.failed_review_reasons_other.theme-dark {
  color: #25ff33; }

app-report-tasks clr-dg-filter {
  display: none !important; }

app-report-tasks .ng-select {
  width: 12rem;
  margin-top: -1rem; }

app-report-tasks .ng-select .ng-has-value .ng-placeholder,
app-report-tasks .ng-select.ng-select-opened .ng-placeholder {
  display: none !important; }

app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding-left: 0.1rem; }

app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-left: 0.2rem;
  border-radius: 5px;
  padding: 0 0.2rem; }
  .theme-light app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #ccc; }
  :host-context(.theme-light) app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #ccc; }
  app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.theme-light {
    border: 1px solid #ccc; }
  .theme-dark app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #dadada; }
  :host-context(.theme-dark) app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #dadada; }
  app-report-tasks .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.theme-dark {
    border: 1px solid #dadada; }

app-firon .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value {
  margin-left: 0.2rem;
  border-radius: 5px;
  padding: 0 0.2rem; }
  .theme-light app-firon .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #ccc; }
  :host-context(.theme-light) app-firon .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #ccc; }
  app-firon .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value.theme-light {
    border: 1px solid #ccc; }
  .theme-dark app-firon .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #dadada; }
  :host-context(.theme-dark) app-firon .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value {
    border: 1px solid #dadada; }
  app-firon .ng-select.ng-select-multiple.hasborder .ng-select-container .ng-value-container .ng-value.theme-dark {
    border: 1px solid #dadada; }

.clr-no-filter * clr-dg-filter {
  display: none !important; }

app-rt-filter-date .clr-form-control,
app-rt-filter-number .clr-form-control {
  margin-top: 0 !important; }

app-todo .clr-form-control {
  margin-top: 0rem !important; }

app-report-stats .graph,
app-report-project .graph,
app-report-client .graph,
app-report-employee .graph {
  font-size: 0.7rem !important; }

app-report-stats .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value,
app-report-project .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value,
app-report-client .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value,
app-report-employee .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
  font-size: 0.7rem !important; }

app-report-stats .legend-item,
app-report-project .legend-item,
app-report-client .legend-item,
app-report-employee .legend-item {
  line-height: 1.2rem !important; }

app-report-stats .advanced-pie-legend .total-value,
app-report-project .advanced-pie-legend .total-value,
app-report-client .advanced-pie-legend .total-value,
app-report-employee .advanced-pie-legend .total-value {
  line-height: 2rem; }

app-report-stats .advanced-pie-legend .legend-items-container .legend-items,
app-report-project .advanced-pie-legend .legend-items-container .legend-items,
app-report-client .advanced-pie-legend .legend-items-container .legend-items,
app-report-employee .advanced-pie-legend .legend-items-container .legend-items {
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 1rem; }

app-firon-detail-modal .clr-wizard .clr-wizard-stepnav-link::before {
  display: none !important; }

app-firon-detail-modal .clr-wizard .clr-wizard-stepnav-item.complete {
  box-shadow: 0.166667rem 0 0 #0079b8 inset !important;
  transition: box-shadow 0.2s ease-in; }

app-project form.clr-form-compact input.clr-input,
app-project form.clr-form-compact select.clr-select {
  position: relative;
  top: -2px; }

.theme-light .text-milestone-meeting {
  color: #21bdd5; }

:host-context(.theme-light) .text-milestone-meeting {
  color: #21bdd5; }

.text-milestone-meeting.theme-light {
  color: #21bdd5; }

.theme-dark .text-milestone-meeting {
  color: #21bdd5; }

:host-context(.theme-dark) .text-milestone-meeting {
  color: #21bdd5; }

.text-milestone-meeting.theme-dark {
  color: #21bdd5; }

.theme-light .text-milestone-deadline {
  color: #ff4136; }

:host-context(.theme-light) .text-milestone-deadline {
  color: #ff4136; }

.text-milestone-deadline.theme-light {
  color: #ff4136; }

.theme-dark .text-milestone-deadline {
  color: #ff4136; }

:host-context(.theme-dark) .text-milestone-deadline {
  color: #ff4136; }

.text-milestone-deadline.theme-dark {
  color: #ff4136; }

.theme-light .text-milestone-reminder {
  color: #ffba10; }

:host-context(.theme-light) .text-milestone-reminder {
  color: #ffba10; }

.text-milestone-reminder.theme-light {
  color: #ffba10; }

.theme-dark .text-milestone-reminder {
  color: #ffba10; }

:host-context(.theme-dark) .text-milestone-reminder {
  color: #ffba10; }

.text-milestone-reminder.theme-dark {
  color: #ffba10; }

.theme-light .text-milestone-release {
  color: #ff4c00; }

:host-context(.theme-light) .text-milestone-release {
  color: #ff4c00; }

.text-milestone-release.theme-light {
  color: #ff4c00; }

.theme-dark .text-milestone-release {
  color: #ff4c00; }

:host-context(.theme-dark) .text-milestone-release {
  color: #ff4c00; }

.text-milestone-release.theme-dark {
  color: #ff4c00; }

.theme-light .text-milestone-call {
  color: #36b358; }

:host-context(.theme-light) .text-milestone-call {
  color: #36b358; }

.text-milestone-call.theme-light {
  color: #36b358; }

.theme-dark .text-milestone-call {
  color: #36b358; }

:host-context(.theme-dark) .text-milestone-call {
  color: #36b358; }

.text-milestone-call.theme-dark {
  color: #36b358; }

* {
  scrollbar-width: thin; }

.theme-light app-project-budgets .text-budget-estimated {
  color: #ffba10; }

:host-context(.theme-light) app-project-budgets .text-budget-estimated {
  color: #ffba10; }

app-project-budgets .text-budget-estimated.theme-light {
  color: #ffba10; }

.theme-dark app-project-budgets .text-budget-estimated {
  color: #ffba10; }

:host-context(.theme-dark) app-project-budgets .text-budget-estimated {
  color: #ffba10; }

app-project-budgets .text-budget-estimated.theme-dark {
  color: #ffba10; }

.theme-light app-project-budgets .text-budget-billed {
  color: #009bff; }

:host-context(.theme-light) app-project-budgets .text-budget-billed {
  color: #009bff; }

app-project-budgets .text-budget-billed.theme-light {
  color: #009bff; }

.theme-dark app-project-budgets .text-budget-billed {
  color: #009bff; }

:host-context(.theme-dark) app-project-budgets .text-budget-billed {
  color: #009bff; }

app-project-budgets .text-budget-billed.theme-dark {
  color: #009bff; }

.theme-light app-project-budgets .text-budget-cancelled {
  color: #ff4136; }

:host-context(.theme-light) app-project-budgets .text-budget-cancelled {
  color: #ff4136; }

app-project-budgets .text-budget-cancelled.theme-light {
  color: #ff4136; }

.theme-dark app-project-budgets .text-budget-cancelled {
  color: #ff4136; }

:host-context(.theme-dark) app-project-budgets .text-budget-cancelled {
  color: #ff4136; }

app-project-budgets .text-budget-cancelled.theme-dark {
  color: #ff4136; }

app-firon .dropdown-menu {
  z-index: 150 !important; }

app-firon .input-no-width .clr-control-container {
  width: unset; }

.theme-light app-project-tasks .text-task-status-backlog, .theme-light
app-firon .text-task-status-backlog, .theme-light
app-dashboard-task .text-task-status-backlog, .theme-light
app-gantt-task .text-task-status-backlog, .theme-light
app-workload-release-modal .text-task-status-backlog {
  color: #70acb5; }

:host-context(.theme-light) app-project-tasks .text-task-status-backlog, :host-context(.theme-light)
app-firon .text-task-status-backlog, :host-context(.theme-light)
app-dashboard-task .text-task-status-backlog, :host-context(.theme-light)
app-gantt-task .text-task-status-backlog, :host-context(.theme-light)
app-workload-release-modal .text-task-status-backlog {
  color: #70acb5; }

app-project-tasks .text-task-status-backlog.theme-light,
app-firon .text-task-status-backlog.theme-light,
app-dashboard-task .text-task-status-backlog.theme-light,
app-gantt-task .text-task-status-backlog.theme-light,
app-workload-release-modal .text-task-status-backlog.theme-light {
  color: #70acb5; }

.theme-dark app-project-tasks .text-task-status-backlog, .theme-dark
app-firon .text-task-status-backlog, .theme-dark
app-dashboard-task .text-task-status-backlog, .theme-dark
app-gantt-task .text-task-status-backlog, .theme-dark
app-workload-release-modal .text-task-status-backlog {
  color: #70acb5; }

:host-context(.theme-dark) app-project-tasks .text-task-status-backlog, :host-context(.theme-dark)
app-firon .text-task-status-backlog, :host-context(.theme-dark)
app-dashboard-task .text-task-status-backlog, :host-context(.theme-dark)
app-gantt-task .text-task-status-backlog, :host-context(.theme-dark)
app-workload-release-modal .text-task-status-backlog {
  color: #70acb5; }

app-project-tasks .text-task-status-backlog.theme-dark,
app-firon .text-task-status-backlog.theme-dark,
app-dashboard-task .text-task-status-backlog.theme-dark,
app-gantt-task .text-task-status-backlog.theme-dark,
app-workload-release-modal .text-task-status-backlog.theme-dark {
  color: #70acb5; }

.theme-light app-project-tasks .text-task-status-todo, .theme-light
app-firon .text-task-status-todo, .theme-light
app-dashboard-task .text-task-status-todo, .theme-light
app-gantt-task .text-task-status-todo, .theme-light
app-workload-release-modal .text-task-status-todo {
  color: #61c84e; }

:host-context(.theme-light) app-project-tasks .text-task-status-todo, :host-context(.theme-light)
app-firon .text-task-status-todo, :host-context(.theme-light)
app-dashboard-task .text-task-status-todo, :host-context(.theme-light)
app-gantt-task .text-task-status-todo, :host-context(.theme-light)
app-workload-release-modal .text-task-status-todo {
  color: #61c84e; }

app-project-tasks .text-task-status-todo.theme-light,
app-firon .text-task-status-todo.theme-light,
app-dashboard-task .text-task-status-todo.theme-light,
app-gantt-task .text-task-status-todo.theme-light,
app-workload-release-modal .text-task-status-todo.theme-light {
  color: #61c84e; }

.theme-dark app-project-tasks .text-task-status-todo, .theme-dark
app-firon .text-task-status-todo, .theme-dark
app-dashboard-task .text-task-status-todo, .theme-dark
app-gantt-task .text-task-status-todo, .theme-dark
app-workload-release-modal .text-task-status-todo {
  color: #61c84e; }

:host-context(.theme-dark) app-project-tasks .text-task-status-todo, :host-context(.theme-dark)
app-firon .text-task-status-todo, :host-context(.theme-dark)
app-dashboard-task .text-task-status-todo, :host-context(.theme-dark)
app-gantt-task .text-task-status-todo, :host-context(.theme-dark)
app-workload-release-modal .text-task-status-todo {
  color: #61c84e; }

app-project-tasks .text-task-status-todo.theme-dark,
app-firon .text-task-status-todo.theme-dark,
app-dashboard-task .text-task-status-todo.theme-dark,
app-gantt-task .text-task-status-todo.theme-dark,
app-workload-release-modal .text-task-status-todo.theme-dark {
  color: #61c84e; }

.theme-light app-project-tasks .text-task-status-inprogress, .theme-light
app-firon .text-task-status-inprogress, .theme-light
app-dashboard-task .text-task-status-inprogress, .theme-light
app-gantt-task .text-task-status-inprogress, .theme-light
app-workload-release-modal .text-task-status-inprogress {
  color: #ffba10; }

:host-context(.theme-light) app-project-tasks .text-task-status-inprogress, :host-context(.theme-light)
app-firon .text-task-status-inprogress, :host-context(.theme-light)
app-dashboard-task .text-task-status-inprogress, :host-context(.theme-light)
app-gantt-task .text-task-status-inprogress, :host-context(.theme-light)
app-workload-release-modal .text-task-status-inprogress {
  color: #ffba10; }

app-project-tasks .text-task-status-inprogress.theme-light,
app-firon .text-task-status-inprogress.theme-light,
app-dashboard-task .text-task-status-inprogress.theme-light,
app-gantt-task .text-task-status-inprogress.theme-light,
app-workload-release-modal .text-task-status-inprogress.theme-light {
  color: #ffba10; }

.theme-dark app-project-tasks .text-task-status-inprogress, .theme-dark
app-firon .text-task-status-inprogress, .theme-dark
app-dashboard-task .text-task-status-inprogress, .theme-dark
app-gantt-task .text-task-status-inprogress, .theme-dark
app-workload-release-modal .text-task-status-inprogress {
  color: #ffba10; }

:host-context(.theme-dark) app-project-tasks .text-task-status-inprogress, :host-context(.theme-dark)
app-firon .text-task-status-inprogress, :host-context(.theme-dark)
app-dashboard-task .text-task-status-inprogress, :host-context(.theme-dark)
app-gantt-task .text-task-status-inprogress, :host-context(.theme-dark)
app-workload-release-modal .text-task-status-inprogress {
  color: #ffba10; }

app-project-tasks .text-task-status-inprogress.theme-dark,
app-firon .text-task-status-inprogress.theme-dark,
app-dashboard-task .text-task-status-inprogress.theme-dark,
app-gantt-task .text-task-status-inprogress.theme-dark,
app-workload-release-modal .text-task-status-inprogress.theme-dark {
  color: #ffba10; }

.theme-light app-project-tasks .text-task-status-review, .theme-light
app-firon .text-task-status-review, .theme-light
app-dashboard-task .text-task-status-review, .theme-light
app-gantt-task .text-task-status-review, .theme-light
app-workload-release-modal .text-task-status-review {
  color: #ff4c00; }

:host-context(.theme-light) app-project-tasks .text-task-status-review, :host-context(.theme-light)
app-firon .text-task-status-review, :host-context(.theme-light)
app-dashboard-task .text-task-status-review, :host-context(.theme-light)
app-gantt-task .text-task-status-review, :host-context(.theme-light)
app-workload-release-modal .text-task-status-review {
  color: #ff4c00; }

app-project-tasks .text-task-status-review.theme-light,
app-firon .text-task-status-review.theme-light,
app-dashboard-task .text-task-status-review.theme-light,
app-gantt-task .text-task-status-review.theme-light,
app-workload-release-modal .text-task-status-review.theme-light {
  color: #ff4c00; }

.theme-dark app-project-tasks .text-task-status-review, .theme-dark
app-firon .text-task-status-review, .theme-dark
app-dashboard-task .text-task-status-review, .theme-dark
app-gantt-task .text-task-status-review, .theme-dark
app-workload-release-modal .text-task-status-review {
  color: #ff4c00; }

:host-context(.theme-dark) app-project-tasks .text-task-status-review, :host-context(.theme-dark)
app-firon .text-task-status-review, :host-context(.theme-dark)
app-dashboard-task .text-task-status-review, :host-context(.theme-dark)
app-gantt-task .text-task-status-review, :host-context(.theme-dark)
app-workload-release-modal .text-task-status-review {
  color: #ff4c00; }

app-project-tasks .text-task-status-review.theme-dark,
app-firon .text-task-status-review.theme-dark,
app-dashboard-task .text-task-status-review.theme-dark,
app-gantt-task .text-task-status-review.theme-dark,
app-workload-release-modal .text-task-status-review.theme-dark {
  color: #ff4c00; }

.theme-light app-project-tasks .text-task-status-reviewing, .theme-light
app-firon .text-task-status-reviewing, .theme-light
app-dashboard-task .text-task-status-reviewing, .theme-light
app-gantt-task .text-task-status-reviewing, .theme-light
app-workload-release-modal .text-task-status-reviewing {
  color: #9d5973; }

:host-context(.theme-light) app-project-tasks .text-task-status-reviewing, :host-context(.theme-light)
app-firon .text-task-status-reviewing, :host-context(.theme-light)
app-dashboard-task .text-task-status-reviewing, :host-context(.theme-light)
app-gantt-task .text-task-status-reviewing, :host-context(.theme-light)
app-workload-release-modal .text-task-status-reviewing {
  color: #9d5973; }

app-project-tasks .text-task-status-reviewing.theme-light,
app-firon .text-task-status-reviewing.theme-light,
app-dashboard-task .text-task-status-reviewing.theme-light,
app-gantt-task .text-task-status-reviewing.theme-light,
app-workload-release-modal .text-task-status-reviewing.theme-light {
  color: #9d5973; }

.theme-dark app-project-tasks .text-task-status-reviewing, .theme-dark
app-firon .text-task-status-reviewing, .theme-dark
app-dashboard-task .text-task-status-reviewing, .theme-dark
app-gantt-task .text-task-status-reviewing, .theme-dark
app-workload-release-modal .text-task-status-reviewing {
  color: #9d5973; }

:host-context(.theme-dark) app-project-tasks .text-task-status-reviewing, :host-context(.theme-dark)
app-firon .text-task-status-reviewing, :host-context(.theme-dark)
app-dashboard-task .text-task-status-reviewing, :host-context(.theme-dark)
app-gantt-task .text-task-status-reviewing, :host-context(.theme-dark)
app-workload-release-modal .text-task-status-reviewing {
  color: #9d5973; }

app-project-tasks .text-task-status-reviewing.theme-dark,
app-firon .text-task-status-reviewing.theme-dark,
app-dashboard-task .text-task-status-reviewing.theme-dark,
app-gantt-task .text-task-status-reviewing.theme-dark,
app-workload-release-modal .text-task-status-reviewing.theme-dark {
  color: #9d5973; }

app-project-tasks .text-task-status-done,
app-firon .text-task-status-done,
app-dashboard-task .text-task-status-done,
app-gantt-task .text-task-status-done,
app-workload-release-modal .text-task-status-done {
  font-weight: 400; }
  .theme-light app-project-tasks .text-task-status-done, .theme-light
  app-firon .text-task-status-done, .theme-light
  app-dashboard-task .text-task-status-done, .theme-light
  app-gantt-task .text-task-status-done, .theme-light
  app-workload-release-modal .text-task-status-done {
    color: #21bdd5; }
  :host-context(.theme-light) app-project-tasks .text-task-status-done, :host-context(.theme-light)
  app-firon .text-task-status-done, :host-context(.theme-light)
  app-dashboard-task .text-task-status-done, :host-context(.theme-light)
  app-gantt-task .text-task-status-done, :host-context(.theme-light)
  app-workload-release-modal .text-task-status-done {
    color: #21bdd5; }
  app-project-tasks .text-task-status-done.theme-light,
  app-firon .text-task-status-done.theme-light,
  app-dashboard-task .text-task-status-done.theme-light,
  app-gantt-task .text-task-status-done.theme-light,
  app-workload-release-modal .text-task-status-done.theme-light {
    color: #21bdd5; }
  .theme-dark app-project-tasks .text-task-status-done, .theme-dark
  app-firon .text-task-status-done, .theme-dark
  app-dashboard-task .text-task-status-done, .theme-dark
  app-gantt-task .text-task-status-done, .theme-dark
  app-workload-release-modal .text-task-status-done {
    color: #21bdd5; }
  :host-context(.theme-dark) app-project-tasks .text-task-status-done, :host-context(.theme-dark)
  app-firon .text-task-status-done, :host-context(.theme-dark)
  app-dashboard-task .text-task-status-done, :host-context(.theme-dark)
  app-gantt-task .text-task-status-done, :host-context(.theme-dark)
  app-workload-release-modal .text-task-status-done {
    color: #21bdd5; }
  app-project-tasks .text-task-status-done.theme-dark,
  app-firon .text-task-status-done.theme-dark,
  app-dashboard-task .text-task-status-done.theme-dark,
  app-gantt-task .text-task-status-done.theme-dark,
  app-workload-release-modal .text-task-status-done.theme-dark {
    color: #21bdd5; }

.theme-light app-project-tasks .text-progress-0, .theme-light
app-firon .text-progress-0, .theme-light
app-dashboard-task .text-progress-0, .theme-light
app-gantt-task .text-progress-0, .theme-light
app-workload-release-modal .text-progress-0 {
  color: #1963ff; }

:host-context(.theme-light) app-project-tasks .text-progress-0, :host-context(.theme-light)
app-firon .text-progress-0, :host-context(.theme-light)
app-dashboard-task .text-progress-0, :host-context(.theme-light)
app-gantt-task .text-progress-0, :host-context(.theme-light)
app-workload-release-modal .text-progress-0 {
  color: #1963ff; }

app-project-tasks .text-progress-0.theme-light,
app-firon .text-progress-0.theme-light,
app-dashboard-task .text-progress-0.theme-light,
app-gantt-task .text-progress-0.theme-light,
app-workload-release-modal .text-progress-0.theme-light {
  color: #1963ff; }

.theme-dark app-project-tasks .text-progress-0, .theme-dark
app-firon .text-progress-0, .theme-dark
app-dashboard-task .text-progress-0, .theme-dark
app-gantt-task .text-progress-0, .theme-dark
app-workload-release-modal .text-progress-0 {
  color: #22acff; }

:host-context(.theme-dark) app-project-tasks .text-progress-0, :host-context(.theme-dark)
app-firon .text-progress-0, :host-context(.theme-dark)
app-dashboard-task .text-progress-0, :host-context(.theme-dark)
app-gantt-task .text-progress-0, :host-context(.theme-dark)
app-workload-release-modal .text-progress-0 {
  color: #22acff; }

app-project-tasks .text-progress-0.theme-dark,
app-firon .text-progress-0.theme-dark,
app-dashboard-task .text-progress-0.theme-dark,
app-gantt-task .text-progress-0.theme-dark,
app-workload-release-modal .text-progress-0.theme-dark {
  color: #22acff; }

.theme-light app-project-tasks .text-progress-10, .theme-light
app-firon .text-progress-10, .theme-light
app-dashboard-task .text-progress-10, .theme-light
app-gantt-task .text-progress-10, .theme-light
app-workload-release-modal .text-progress-10 {
  color: #37a93d; }

:host-context(.theme-light) app-project-tasks .text-progress-10, :host-context(.theme-light)
app-firon .text-progress-10, :host-context(.theme-light)
app-dashboard-task .text-progress-10, :host-context(.theme-light)
app-gantt-task .text-progress-10, :host-context(.theme-light)
app-workload-release-modal .text-progress-10 {
  color: #37a93d; }

app-project-tasks .text-progress-10.theme-light,
app-firon .text-progress-10.theme-light,
app-dashboard-task .text-progress-10.theme-light,
app-gantt-task .text-progress-10.theme-light,
app-workload-release-modal .text-progress-10.theme-light {
  color: #37a93d; }

.theme-dark app-project-tasks .text-progress-10, .theme-dark
app-firon .text-progress-10, .theme-dark
app-dashboard-task .text-progress-10, .theme-dark
app-gantt-task .text-progress-10, .theme-dark
app-workload-release-modal .text-progress-10 {
  color: #25ff33; }

:host-context(.theme-dark) app-project-tasks .text-progress-10, :host-context(.theme-dark)
app-firon .text-progress-10, :host-context(.theme-dark)
app-dashboard-task .text-progress-10, :host-context(.theme-dark)
app-gantt-task .text-progress-10, :host-context(.theme-dark)
app-workload-release-modal .text-progress-10 {
  color: #25ff33; }

app-project-tasks .text-progress-10.theme-dark,
app-firon .text-progress-10.theme-dark,
app-dashboard-task .text-progress-10.theme-dark,
app-gantt-task .text-progress-10.theme-dark,
app-workload-release-modal .text-progress-10.theme-dark {
  color: #25ff33; }

.theme-light app-project-tasks .text-progress-40, .theme-light
app-firon .text-progress-40, .theme-light
app-dashboard-task .text-progress-40, .theme-light
app-gantt-task .text-progress-40, .theme-light
app-workload-release-modal .text-progress-40 {
  color: #7a7f33; }

:host-context(.theme-light) app-project-tasks .text-progress-40, :host-context(.theme-light)
app-firon .text-progress-40, :host-context(.theme-light)
app-dashboard-task .text-progress-40, :host-context(.theme-light)
app-gantt-task .text-progress-40, :host-context(.theme-light)
app-workload-release-modal .text-progress-40 {
  color: #7a7f33; }

app-project-tasks .text-progress-40.theme-light,
app-firon .text-progress-40.theme-light,
app-dashboard-task .text-progress-40.theme-light,
app-gantt-task .text-progress-40.theme-light,
app-workload-release-modal .text-progress-40.theme-light {
  color: #7a7f33; }

.theme-dark app-project-tasks .text-progress-40, .theme-dark
app-firon .text-progress-40, .theme-dark
app-dashboard-task .text-progress-40, .theme-dark
app-gantt-task .text-progress-40, .theme-dark
app-workload-release-modal .text-progress-40 {
  color: #ffff22; }

:host-context(.theme-dark) app-project-tasks .text-progress-40, :host-context(.theme-dark)
app-firon .text-progress-40, :host-context(.theme-dark)
app-dashboard-task .text-progress-40, :host-context(.theme-dark)
app-gantt-task .text-progress-40, :host-context(.theme-dark)
app-workload-release-modal .text-progress-40 {
  color: #ffff22; }

app-project-tasks .text-progress-40.theme-dark,
app-firon .text-progress-40.theme-dark,
app-dashboard-task .text-progress-40.theme-dark,
app-gantt-task .text-progress-40.theme-dark,
app-workload-release-modal .text-progress-40.theme-dark {
  color: #ffff22; }

.theme-light app-project-tasks .text-progress-80, .theme-light
app-firon .text-progress-80, .theme-light
app-dashboard-task .text-progress-80, .theme-light
app-gantt-task .text-progress-80, .theme-light
app-workload-release-modal .text-progress-80 {
  color: #ff3523; }

:host-context(.theme-light) app-project-tasks .text-progress-80, :host-context(.theme-light)
app-firon .text-progress-80, :host-context(.theme-light)
app-dashboard-task .text-progress-80, :host-context(.theme-light)
app-gantt-task .text-progress-80, :host-context(.theme-light)
app-workload-release-modal .text-progress-80 {
  color: #ff3523; }

app-project-tasks .text-progress-80.theme-light,
app-firon .text-progress-80.theme-light,
app-dashboard-task .text-progress-80.theme-light,
app-gantt-task .text-progress-80.theme-light,
app-workload-release-modal .text-progress-80.theme-light {
  color: #ff3523; }

.theme-dark app-project-tasks .text-progress-80, .theme-dark
app-firon .text-progress-80, .theme-dark
app-dashboard-task .text-progress-80, .theme-dark
app-gantt-task .text-progress-80, .theme-dark
app-workload-release-modal .text-progress-80 {
  color: #ff3523; }

:host-context(.theme-dark) app-project-tasks .text-progress-80, :host-context(.theme-dark)
app-firon .text-progress-80, :host-context(.theme-dark)
app-dashboard-task .text-progress-80, :host-context(.theme-dark)
app-gantt-task .text-progress-80, :host-context(.theme-dark)
app-workload-release-modal .text-progress-80 {
  color: #ff3523; }

app-project-tasks .text-progress-80.theme-dark,
app-firon .text-progress-80.theme-dark,
app-dashboard-task .text-progress-80.theme-dark,
app-gantt-task .text-progress-80.theme-dark,
app-workload-release-modal .text-progress-80.theme-dark {
  color: #ff3523; }

.theme-light app-project-tasks .text-progress-overtime, .theme-light
app-firon .text-progress-overtime, .theme-light
app-dashboard-task .text-progress-overtime, .theme-light
app-gantt-task .text-progress-overtime, .theme-light
app-workload-release-modal .text-progress-overtime {
  color: #9d5973; }

:host-context(.theme-light) app-project-tasks .text-progress-overtime, :host-context(.theme-light)
app-firon .text-progress-overtime, :host-context(.theme-light)
app-dashboard-task .text-progress-overtime, :host-context(.theme-light)
app-gantt-task .text-progress-overtime, :host-context(.theme-light)
app-workload-release-modal .text-progress-overtime {
  color: #9d5973; }

app-project-tasks .text-progress-overtime.theme-light,
app-firon .text-progress-overtime.theme-light,
app-dashboard-task .text-progress-overtime.theme-light,
app-gantt-task .text-progress-overtime.theme-light,
app-workload-release-modal .text-progress-overtime.theme-light {
  color: #9d5973; }

.theme-dark app-project-tasks .text-progress-overtime, .theme-dark
app-firon .text-progress-overtime, .theme-dark
app-dashboard-task .text-progress-overtime, .theme-dark
app-gantt-task .text-progress-overtime, .theme-dark
app-workload-release-modal .text-progress-overtime {
  color: #9d5973; }

:host-context(.theme-dark) app-project-tasks .text-progress-overtime, :host-context(.theme-dark)
app-firon .text-progress-overtime, :host-context(.theme-dark)
app-dashboard-task .text-progress-overtime, :host-context(.theme-dark)
app-gantt-task .text-progress-overtime, :host-context(.theme-dark)
app-workload-release-modal .text-progress-overtime {
  color: #9d5973; }

app-project-tasks .text-progress-overtime.theme-dark,
app-firon .text-progress-overtime.theme-dark,
app-dashboard-task .text-progress-overtime.theme-dark,
app-gantt-task .text-progress-overtime.theme-dark,
app-workload-release-modal .text-progress-overtime.theme-dark {
  color: #9d5973; }

.theme-light app-project clr-dg-row-detail {
  background-color: #fff !important; }

:host-context(.theme-light) app-project clr-dg-row-detail {
  background-color: #fff !important; }

app-project clr-dg-row-detail.theme-light {
  background-color: #fff !important; }

.theme-dark app-project clr-dg-row-detail {
  background-color: #21242b !important; }

:host-context(.theme-dark) app-project clr-dg-row-detail {
  background-color: #21242b !important; }

app-project clr-dg-row-detail.theme-dark {
  background-color: #21242b !important; }

.theme-light app-project clr-dg-row-detail .datagrid-cell, .theme-light
app-project clr-dg-row-detail .datagrid-expandable-caret {
  background-color: #fff !important; }

:host-context(.theme-light) app-project clr-dg-row-detail .datagrid-cell, :host-context(.theme-light)
app-project clr-dg-row-detail .datagrid-expandable-caret {
  background-color: #fff !important; }

app-project clr-dg-row-detail .datagrid-cell.theme-light,
app-project clr-dg-row-detail .datagrid-expandable-caret.theme-light {
  background-color: #fff !important; }

.theme-dark app-project clr-dg-row-detail .datagrid-cell, .theme-dark
app-project clr-dg-row-detail .datagrid-expandable-caret {
  background-color: #21242b !important; }

:host-context(.theme-dark) app-project clr-dg-row-detail .datagrid-cell, :host-context(.theme-dark)
app-project clr-dg-row-detail .datagrid-expandable-caret {
  background-color: #21242b !important; }

app-project clr-dg-row-detail .datagrid-cell.theme-dark,
app-project clr-dg-row-detail .datagrid-expandable-caret.theme-dark {
  background-color: #21242b !important; }

shared-generic-datagrid-filter li .clr-control-container {
  width: 100%; }

shared-generic-datagrid-filter li span.badge {
  float: right;
  margin: 0.25rem 0 0 0.25rem; }

clr-input-container .clr-control-container {
  width: 100%; }
  clr-input-container .clr-control-container input {
    width: 100%; }

app-edit-task {
  font-size: 0.7rem; }

app-forms-add-task * .ng-input > input {
  height: 1.35rem; }

app-forms-add-task .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  padding-bottom: 0;
  padding-top: 0; }

app-add-multiple-task {
  font-size: 0.6rem !important; }
  app-add-multiple-task li {
    list-style: disc !important;
    margin-left: 0.6rem; }
  app-add-multiple-task .modal-header > h3 {
    font-size: 0.8rem !important; }
  app-add-multiple-task * :not(button) {
    font-size: 0.6rem !important; }
  app-add-multiple-task ng-dropdown-panel {
    min-width: 15rem !important; }
  app-add-multiple-task .ng-option {
    padding-left: 0.4rem !important; }
  app-add-multiple-task .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: 7px; }
  app-add-multiple-task .ng-value {
    bottom: 7px;
    position: absolute;
    margin-top: 0; }
  app-add-multiple-task .ng-select .ng-select-container .ng-value-container {
    align-items: baseline;
    padding: 0.4375em 0;
    border-top: 1rem solid transparent; }

app-forms-add-task .sub-no-mt > clr-date-container,
app-edit-task .sub-no-mt > clr-date-container {
  margin-top: 0; }

app-forms-add-task .sub-no-mt .clr-input-group,
app-edit-task .sub-no-mt .clr-input-group {
  color: #000;
  display: inline-block;
  border-bottom: 1px solid #9a9a9a;
  background: linear-gradient(to bottom, transparent 95%, #0095d3 95%) no-repeat;
  background-size: auto;
  background-size: 0 100%;
  transition: background-size 0.2s ease;
  margin-right: 1rem;
  height: 22px; }

app-forms-add-task .ng-select .ng-select-container .ng-value-container .ng-input,
app-edit-task .ng-select .ng-select-container .ng-value-container .ng-input {
  bottom: -0.01rem;
  top: 0rem; }
  app-forms-add-task .ng-select .ng-select-container .ng-value-container .ng-input > input,
  app-edit-task .ng-select .ng-select-container .ng-value-container .ng-input > input {
    width: calc(100% - 0.5rem); }

app-forms-add-task .ng-select,
app-edit-task .ng-select {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0rem; }
  app-forms-add-task .ng-select input,
  app-edit-task .ng-select input {
    margin-top: 0.2rem; }
  app-forms-add-task .ng-select .ng-select-container,
  app-edit-task .ng-select .ng-select-container {
    min-height: 1.5rem !important;
    align-items: baseline; }
    app-forms-add-task .ng-select .ng-select-container .ng-value-container,
    app-edit-task .ng-select .ng-select-container .ng-value-container {
      align-items: baseline;
      padding: 0em 0;
      border-top: 0 solid transparent; }

app-modal clr-input-container .clr-control-container input {
  /* allow for error icon width */
  width: calc(100% - 20px); }

.Main.is-collapsed app-firon-detail-panel .firon-fixed-sidebar {
  width: calc(25% - 10px); }

clr-textarea-container .clr-control-container textarea {
  width: 100%; }

shared-toast {
  z-index: 1060;
  position: absolute;
  top: 0; }
  shared-toast .toaster {
    margin-right: 0.4rem;
    max-width: 30vw;
    min-width: 15vw;
    position: fixed;
    top: 0;
    margin-top: 0.2rem;
    right: 0; }
  shared-toast .top-toaster {
    width: 100vw;
    position: fixed;
    top: 0; }
  shared-toast .alert-text {
    max-width: 30vw;
    min-width: 15vw; }
  shared-toast .alert {
    overflow: hidden; }

form.clr-form-horizontal clr-input-container .clr-control-label,
form.clr-form-horizontal clr-select-container .clr-control-label {
  line-height: 1rem; }

.clr-wizard-footer-buttons clr-checkbox-wrapper .clr-control-label {
  line-height: 1.1rem; }

.task-commits-container .stack-view {
  -webkit-mask-image: unset; }
  .task-commits-container .stack-view .stack-block {
    overflow: hidden; }
    .task-commits-container .stack-view .stack-block .stack-block-caret {
      float: left;
      margin-top: 5px; }

.task-commits-container .tooltip-sm:focus::after,
.task-commits-container .tooltip-sm:hover::after {
  -webkit-transform: translate(-120%, 1.2rem);
  transform: translate(-120%, 1.2rem); }

app-log-in {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100vw;
  min-height: 100vh; }

app-sign-up {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100vw;
  min-height: 100vh; }

.overflow-x-hidden {
  overflow-x: hidden; }

app-firon-helper {
  font-size: 0.7rem; }

div.avatar-container {
  text-align: center; }
  div.avatar-container app-long-avatar {
    position: relative;
    font-size: 4em; }

td app-long-avatar .label {
  vertical-align: inherit; }

app-long-avatar.add-emp .label {
  background-color: #0277bd !important;
  font-size: 1.2rem;
  font-weight: 400; }

app-long-avatar.appelami .label {
  background-color: #e7621a !important; }

.tooltip-sm {
  position: relative; }
  .tooltip-sm::after {
    background: rgba(69, 77, 93, 0.95);
    border-radius: 0.1rem;
    bottom: 100%;
    color: #fff;
    content: attr(data-tooltip);
    display: block;
    font-size: 0.7rem;
    left: 50%;
    max-width: 320px;
    opacity: 0;
    overflow: hidden;
    padding: 0.2rem 0.4rem;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    transform: translate(-50%, 0.4rem);
    transition: opacity 0.2s, transform 0.2s;
    white-space: pre;
    z-index: 300; }
  .tooltip-sm:focus::after, .tooltip-sm:hover::after {
    opacity: 1;
    transform: translate(-50%, -0.2rem); }
  .tooltip-sm[disabled], .tooltip-sm.disabled {
    pointer-events: auto; }
  .tooltip-sm.tooltip-right::after {
    bottom: 50%;
    left: 100%;
    transform: translate(-0.2rem, 50%); }
  .tooltip-sm.tooltip-right:focus::after, .tooltip-sm.tooltip-right:hover::after {
    transform: translate(0.2rem, 50%); }
  .tooltip-sm.tooltip-bottom::after {
    bottom: auto;
    top: 100%;
    transform: translate(-50%, -0.4rem); }
  .tooltip-sm.tooltip-bottom:focus::after, .tooltip-sm.tooltip-bottom:hover::after {
    transform: translate(-50%, 0.2rem); }
  .tooltip-sm.tooltip-left::after {
    bottom: 50%;
    left: auto;
    right: 100%;
    transform: translate(0.4rem, 50%); }
  .tooltip-sm.tooltip-left:focus::after, .tooltip-sm.tooltip-left:hover::after {
    transform: translate(-0.2rem, 50%); }

.tooltip-sm-click {
  position: relative; }
  .tooltip-sm-click::after {
    background: rgba(69, 77, 93, 0.95);
    border-radius: 0.1rem;
    bottom: 100%;
    color: #fff;
    content: attr(data-tooltip);
    display: block;
    font-size: 0.7rem;
    left: 50%;
    max-width: 320px;
    opacity: 0;
    overflow: hidden;
    padding: 0.2rem 0.4rem;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    transform: translate(-50%, 0.4rem);
    transition: opacity 0.2s, transform 0.2s;
    white-space: pre;
    z-index: 300; }
  .tooltip-sm-click:focus::after, .tooltip-sm-click.tp-active::after {
    opacity: 1;
    transform: translate(-50%, -0.2rem); }
  .tooltip-sm-click[disabled], .tooltip-sm-click.disabled {
    pointer-events: auto; }
  .tooltip-sm-click.tooltip-right::after {
    bottom: 50%;
    left: 100%;
    transform: translate(-0.2rem, 50%); }
  .tooltip-sm-click.tooltip-right:focus::after, .tooltip-sm-click.tooltip-right.tp-active::after {
    transform: translate(0.2rem, 50%); }
  .tooltip-sm-click.tooltip-bottom::after {
    bottom: auto;
    top: 100%;
    transform: translate(-50%, -0.4rem); }
  .tooltip-sm-click.tooltip-bottom:focus::after, .tooltip-sm-click.tooltip-bottom.tp-active::after {
    transform: translate(-50%, 0.2rem); }
  .tooltip-sm-click.tooltip-left::after {
    bottom: 50%;
    left: auto;
    right: 100%;
    transform: translate(0.4rem, 50%); }
  .tooltip-sm-click.tooltip-left:focus::after, .tooltip-sm-click.tooltip-left.tp-active::after {
    transform: translate(-0.2rem, 50%); }

[appClipboard] textarea {
  position: fixed !important;
  top: -15px !important;
  left: 0 !important;
  height: 1px !important; }

kbd {
  background: #444444;
  border-radius: 0.1rem;
  color: #fff;
  font-size: 0.7rem;
  line-height: 1.2;
  padding: 0.1rem 0.2rem; }

.breadcrumb .breadcrumb-item {
  color: #eef6fb; }

.breadcrumb .breadcrumb-item {
  color: #667189;
  display: inline-block;
  margin: 0;
  padding: 0.2rem 0; }

.breadcrumb .breadcrumb-item:not(:first-child)::before {
  color: #969696;
  content: '/';
  padding: 0 0.3rem; }

textarea {
  /* FIXME https://github.com/chrismsimpson/Metropolis/issues/15 Metropolis fucks with markdown code blocks */
  font-family: 'Avenir Next', 'Helvetica Neue', Arial, sans-serif; }

.monospace,
.monospace-date {
  font-family: 'Source Code Pro', monospace; }

.task-label {
  max-width: 10rem;
  line-height: 0.5rem; }

table.info-table {
  font-size: 0.7rem;
  border-spacing: 0.5rem 0; }
  table.info-table tr td {
    border-right: 1px solid #b7b7b7;
    padding-right: 0.5rem; }
  table.info-table tr td:last-of-type,
  table.info-table tr td:first-of-type {
    border-right: none; }

.modal-title {
  word-break: break-all; }

.Sidebar-navItem {
  white-space: nowrap;
  overflow: hidden;
  transition: padding-left 0.4s ease;
  position: relative;
  margin-top: 0; }
  @media (max-width: null) {
    .Sidebar-navItem {
      padding-left: 17px; } }
  .theme-light .Sidebar-navItem.active {
    background-color: #fff; }
  :host-context(.theme-light) .Sidebar-navItem.active {
    background-color: #fff; }
  .Sidebar-navItem.active.theme-light {
    background-color: #fff; }
  .theme-dark .Sidebar-navItem.active {
    background-color: #303d4f; }
  :host-context(.theme-dark) .Sidebar-navItem.active {
    background-color: #303d4f; }
  .Sidebar-navItem.active.theme-dark {
    background-color: #303d4f; }
  .theme-light .Sidebar-navItem:hover {
    background-color: #ccc; }
  :host-context(.theme-light) .Sidebar-navItem:hover {
    background-color: #ccc; }
  .Sidebar-navItem:hover.theme-light {
    background-color: #ccc; }
  .theme-dark .Sidebar-navItem:hover {
    background-color: black; }
  :host-context(.theme-dark) .Sidebar-navItem:hover {
    background-color: black; }
  .Sidebar-navItem:hover.theme-dark {
    background-color: black; }
  .Sidebar-navItem:not([data-tab='timer']):hover {
    cursor: pointer; }
  .Sidebar-navItem a {
    outline: 0;
    opacity: 1;
    transition: opacity 0.4s ease;
    padding: 0.5rem 0 0.5rem 25px;
    display: block; }
    .theme-light .Sidebar-navItem a {
      color: #565656 !important; }
    :host-context(.theme-light) .Sidebar-navItem a {
      color: #565656 !important; }
    .Sidebar-navItem a.theme-light {
      color: #565656 !important; }
    .theme-dark .Sidebar-navItem a {
      color: #dadada !important; }
    :host-context(.theme-dark) .Sidebar-navItem a {
      color: #dadada !important; }
    .Sidebar-navItem a.theme-dark {
      color: #dadada !important; }
    @media (max-height: 900px) {
      .Sidebar-navItem a {
        padding-top: 0.35rem;
        padding-bottom: 0.35rem; } }
    .Sidebar-navItem a:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
    @media (max-width: null) {
      .Sidebar-navItem a {
        display: none; } }

code.language-inline-diff .token.deletions,
pre.language-inline-diff .token.deletions {
  color: #f52f22; }

code.language-inline-diff .token.insertions,
pre.language-inline-diff .token.insertions {
  color: #48960c; }

.card-footer clr-icon {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }

.firon-fixed-sidebar .card-block,
.firon-fixed-sidebar .card-footer,
.firon-fixed-sidebar .card-header,
.firon-fixed-sidebar .card > .list,
.firon-fixed-sidebar .card > .list-unstyled {
  padding: 0.2rem 0.3rem; }

.firon-fixed-sidebar .card-block .btn,
.firon-fixed-sidebar .card-block .btn.btn-link,
.firon-fixed-sidebar .card-block .card-link,
.firon-fixed-sidebar .card-footer .btn,
.firon-fixed-sidebar .card-footer .btn.btn-link,
.firon-fixed-sidebar .card-footer .card-link {
  margin: 0 0.3rem 0 0; }

.firon-fixed-sidebar .card-footer clr-icon {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem; }

.firon-fixed-sidebar form {
  padding-top: 0.2rem; }

.firon-fixed-sidebar .card {
  margin-top: 0.5rem; }

.firon-fixed-sidebar .modal .card-block,
.firon-fixed-sidebar .modal .card-footer,
.firon-fixed-sidebar .modal .card-header,
.firon-fixed-sidebar .modal .card > .list,
.firon-fixed-sidebar .modal .card > .list-unstyled {
  padding: 0.5rem 0.75rem; }

.firon-fixed-sidebar .modal .card-footer clr-icon {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }

.firon-fixed-sidebar .modal form {
  padding-top: 0.5rem; }

.firon-fixed-sidebar .modal .card {
  margin-top: 1rem; }

.modal-footer .btn-footer-left {
  margin-left: 0;
  margin-right: auto; }

.clr-wizard .modal-dialog .modal-dialog {
  height: unset; }
  .clr-wizard .modal-dialog .modal-dialog .modal-content {
    width: 100%;
    flex: unset;
    padding: 1rem; }
    .clr-wizard .modal-dialog .modal-dialog .modal-content .modal-header {
      padding: 0 0 1rem; }
    .clr-wizard .modal-dialog .modal-dialog .modal-content .modal-footer {
      display: flex;
      flex: unset;
      height: unset;
      min-height: unset; }

.legend-label {
  list-style: none !important; }

.card-text ul {
  margin-left: 1rem; }
  .card-text ul li {
    list-style: disc;
    padding: 0.2rem 0; }

.card-text p {
  margin-top: 0; }
  .card-text p:empty {
    display: none; }

.spinner-container {
  margin-top: 50vh;
  text-align: center;
  font-size: 2rem; }

.clr-input-group {
  height: 1rem; }

clr-textarea-container.p-0 .clr-control-container {
  padding: 0; }

clr-signpost button.signpost-action {
  height: 1rem;
  line-height: 100%; }

clr-signpost clr-signpost-content {
  cursor: default; }
  clr-signpost clr-signpost-content .signpost-content-header .signpost-action.close clr-icon {
    margin-top: -1rem; }
  clr-signpost clr-signpost-content .signpost-content-body {
    padding: 0 0.5rem 0.5rem; }

.theme-light clr-signpost .signpost-flex-wrap {
  background-color: #fff;
  color: #565656; }

:host-context(.theme-light) clr-signpost .signpost-flex-wrap {
  background-color: #fff;
  color: #565656; }

clr-signpost .signpost-flex-wrap.theme-light {
  background-color: #fff;
  color: #565656; }

.theme-dark clr-signpost .signpost-flex-wrap {
  background-color: #21242b;
  color: #dadada; }

:host-context(.theme-dark) clr-signpost .signpost-flex-wrap {
  background-color: #21242b;
  color: #dadada; }

clr-signpost .signpost-flex-wrap.theme-dark {
  background-color: #21242b;
  color: #dadada; }

.theme-light clr-signpost .signpost-content.bottom-left .popover-pointer:before, .theme-light
clr-signpost .signpost-content.bottom-middle .popover-pointer:before, .theme-light
clr-signpost .signpost-content.bottom-right .popover-pointer:before {
  border-bottom-color: #fff; }

:host-context(.theme-light) clr-signpost .signpost-content.bottom-left .popover-pointer:before, :host-context(.theme-light)
clr-signpost .signpost-content.bottom-middle .popover-pointer:before, :host-context(.theme-light)
clr-signpost .signpost-content.bottom-right .popover-pointer:before {
  border-bottom-color: #fff; }

clr-signpost .signpost-content.bottom-left .popover-pointer:before.theme-light,
clr-signpost .signpost-content.bottom-middle .popover-pointer:before.theme-light,
clr-signpost .signpost-content.bottom-right .popover-pointer:before.theme-light {
  border-bottom-color: #fff; }

.theme-dark clr-signpost .signpost-content.bottom-left .popover-pointer:before, .theme-dark
clr-signpost .signpost-content.bottom-middle .popover-pointer:before, .theme-dark
clr-signpost .signpost-content.bottom-right .popover-pointer:before {
  border-bottom-color: #21242b; }

:host-context(.theme-dark) clr-signpost .signpost-content.bottom-left .popover-pointer:before, :host-context(.theme-dark)
clr-signpost .signpost-content.bottom-middle .popover-pointer:before, :host-context(.theme-dark)
clr-signpost .signpost-content.bottom-right .popover-pointer:before {
  border-bottom-color: #21242b; }

clr-signpost .signpost-content.bottom-left .popover-pointer:before.theme-dark,
clr-signpost .signpost-content.bottom-middle .popover-pointer:before.theme-dark,
clr-signpost .signpost-content.bottom-right .popover-pointer:before.theme-dark {
  border-bottom-color: #21242b; }

.theme-light .status-backlog {
  color: #70acb5; }

:host-context(.theme-light) .status-backlog {
  color: #70acb5; }

.status-backlog.theme-light {
  color: #70acb5; }

.theme-dark .status-backlog {
  color: #70acb5; }

:host-context(.theme-dark) .status-backlog {
  color: #70acb5; }

.status-backlog.theme-dark {
  color: #70acb5; }

.theme-light .status-todo {
  color: #61c84e; }

:host-context(.theme-light) .status-todo {
  color: #61c84e; }

.status-todo.theme-light {
  color: #61c84e; }

.theme-dark .status-todo {
  color: #61c84e; }

:host-context(.theme-dark) .status-todo {
  color: #61c84e; }

.status-todo.theme-dark {
  color: #61c84e; }

.theme-light .status-inprogress {
  color: #ffba10; }

:host-context(.theme-light) .status-inprogress {
  color: #ffba10; }

.status-inprogress.theme-light {
  color: #ffba10; }

.theme-dark .status-inprogress {
  color: #ffba10; }

:host-context(.theme-dark) .status-inprogress {
  color: #ffba10; }

.status-inprogress.theme-dark {
  color: #ffba10; }

.theme-light .status-review {
  color: #ff4c00; }

:host-context(.theme-light) .status-review {
  color: #ff4c00; }

.status-review.theme-light {
  color: #ff4c00; }

.theme-dark .status-review {
  color: #ff4c00; }

:host-context(.theme-dark) .status-review {
  color: #ff4c00; }

.status-review.theme-dark {
  color: #ff4c00; }

.theme-light .status-reviewing {
  color: #9d5973; }

:host-context(.theme-light) .status-reviewing {
  color: #9d5973; }

.status-reviewing.theme-light {
  color: #9d5973; }

.theme-dark .status-reviewing {
  color: #9d5973; }

:host-context(.theme-dark) .status-reviewing {
  color: #9d5973; }

.status-reviewing.theme-dark {
  color: #9d5973; }

.status-done {
  font-weight: 400; }
  .theme-light .status-done {
    color: #21bdd5; }
  :host-context(.theme-light) .status-done {
    color: #21bdd5; }
  .status-done.theme-light {
    color: #21bdd5; }
  .theme-dark .status-done {
    color: #21bdd5; }
  :host-context(.theme-dark) .status-done {
    color: #21bdd5; }
  .status-done.theme-dark {
    color: #21bdd5; }

.text-light {
  color: #fff !important; }

.text-dark {
  color: #3b4351 !important; }

.text-error {
  color: #e53935 !important; }

.text-blue {
  color: #0095d3; }

.theme-light .close clr-icon {
  fill: #737373; }

:host-context(.theme-light) .close clr-icon {
  fill: #737373; }

.close clr-icon.theme-light {
  fill: #737373; }

.theme-dark .close clr-icon {
  fill: #fff; }

:host-context(.theme-dark) .close clr-icon {
  fill: #fff; }

.close clr-icon.theme-dark {
  fill: #fff; }

clr-alert .alert-app-level .close clr-icon {
  fill: #fff !important; }

.theme-light .close:focus, .theme-light
.clr-checkbox-wrapper input[type='checkbox']:focus + label::before {
  box-shadow: 0 0 2px 2px #6bc1e3; }

:host-context(.theme-light) .close:focus, :host-context(.theme-light)
.clr-checkbox-wrapper input[type='checkbox']:focus + label::before {
  box-shadow: 0 0 2px 2px #6bc1e3; }

.close:focus.theme-light,
.clr-checkbox-wrapper input[type='checkbox']:focus + label::before.theme-light {
  box-shadow: 0 0 2px 2px #6bc1e3; }

.theme-dark .close:focus, .theme-dark
.clr-checkbox-wrapper input[type='checkbox']:focus + label::before {
  box-shadow: 0 0 2px 2px #e7e9ed; }

:host-context(.theme-dark) .close:focus, :host-context(.theme-dark)
.clr-checkbox-wrapper input[type='checkbox']:focus + label::before {
  box-shadow: 0 0 2px 2px #e7e9ed; }

.close:focus.theme-dark,
.clr-checkbox-wrapper input[type='checkbox']:focus + label::before.theme-dark {
  box-shadow: 0 0 2px 2px #e7e9ed; }

.theme-light .modal-dialog .modal-content {
  background-color: #fff; }

:host-context(.theme-light) .modal-dialog .modal-content {
  background-color: #fff; }

.modal-dialog .modal-content.theme-light {
  background-color: #fff; }

.theme-dark .modal-dialog .modal-content {
  background-color: #21242b; }

:host-context(.theme-dark) .modal-dialog .modal-content {
  background-color: #21242b; }

.modal-dialog .modal-content.theme-dark {
  background-color: #21242b; }

.theme-light .modal-header, .theme-light
.modal-header .modal-title {
  color: #565656; }

:host-context(.theme-light) .modal-header, :host-context(.theme-light)
.modal-header .modal-title {
  color: #565656; }

.modal-header.theme-light,
.modal-header .modal-title.theme-light {
  color: #565656; }

.theme-dark .modal-header, .theme-dark
.modal-header .modal-title {
  color: #dadada; }

:host-context(.theme-dark) .modal-header, :host-context(.theme-dark)
.modal-header .modal-title {
  color: #dadada; }

.modal-header.theme-dark,
.modal-header .modal-title.theme-dark {
  color: #dadada; }

.theme-light .modal-body {
  color: #565656; }

:host-context(.theme-light) .modal-body {
  color: #565656; }

.modal-body.theme-light {
  color: #565656; }

.theme-dark .modal-body {
  color: #dadada; }

:host-context(.theme-dark) .modal-body {
  color: #dadada; }

.modal-body.theme-dark {
  color: #dadada; }

.theme-light .clr-control-label {
  color: #444; }

:host-context(.theme-light) .clr-control-label {
  color: #444; }

.clr-control-label.theme-light {
  color: #444; }

.theme-dark .clr-control-label {
  color: #f5f5f5; }

:host-context(.theme-dark) .clr-control-label {
  color: #f5f5f5; }

.clr-control-label.theme-dark {
  color: #f5f5f5; }

.theme-light .clr-multiselect-wrapper select, .theme-light
.clr-select-wrapper select {
  color: #565656; }

:host-context(.theme-light) .clr-multiselect-wrapper select, :host-context(.theme-light)
.clr-select-wrapper select {
  color: #565656; }

.clr-multiselect-wrapper select.theme-light,
.clr-select-wrapper select.theme-light {
  color: #565656; }

.theme-dark .clr-multiselect-wrapper select, .theme-dark
.clr-select-wrapper select {
  color: #dadada; }

:host-context(.theme-dark) .clr-multiselect-wrapper select, :host-context(.theme-dark)
.clr-select-wrapper select {
  color: #dadada; }

.clr-multiselect-wrapper select.theme-dark,
.clr-select-wrapper select.theme-dark {
  color: #dadada; }

.theme-light input[type='date'], .theme-light
input[type='datetime-local'], .theme-light
input[type='email'], .theme-light
input[type='number'], .theme-light
input[type='password'], .theme-light
input[type='tel'], .theme-light
input[type='text'], .theme-light
input[type='time'], .theme-light
input[type='url'] {
  color: #000; }

:host-context(.theme-light) input[type='date'], :host-context(.theme-light)
input[type='datetime-local'], :host-context(.theme-light)
input[type='email'], :host-context(.theme-light)
input[type='number'], :host-context(.theme-light)
input[type='password'], :host-context(.theme-light)
input[type='tel'], :host-context(.theme-light)
input[type='text'], :host-context(.theme-light)
input[type='time'], :host-context(.theme-light)
input[type='url'] {
  color: #000; }

input[type='date'].theme-light,
input[type='datetime-local'].theme-light,
input[type='email'].theme-light,
input[type='number'].theme-light,
input[type='password'].theme-light,
input[type='tel'].theme-light,
input[type='text'].theme-light,
input[type='time'].theme-light,
input[type='url'].theme-light {
  color: #000; }

.theme-dark input[type='date'], .theme-dark
input[type='datetime-local'], .theme-dark
input[type='email'], .theme-dark
input[type='number'], .theme-dark
input[type='password'], .theme-dark
input[type='tel'], .theme-dark
input[type='text'], .theme-dark
input[type='time'], .theme-dark
input[type='url'] {
  color: #fff; }

:host-context(.theme-dark) input[type='date'], :host-context(.theme-dark)
input[type='datetime-local'], :host-context(.theme-dark)
input[type='email'], :host-context(.theme-dark)
input[type='number'], :host-context(.theme-dark)
input[type='password'], :host-context(.theme-dark)
input[type='tel'], :host-context(.theme-dark)
input[type='text'], :host-context(.theme-dark)
input[type='time'], :host-context(.theme-dark)
input[type='url'] {
  color: #fff; }

input[type='date'].theme-dark,
input[type='datetime-local'].theme-dark,
input[type='email'].theme-dark,
input[type='number'].theme-dark,
input[type='password'].theme-dark,
input[type='tel'].theme-dark,
input[type='text'].theme-dark,
input[type='time'].theme-dark,
input[type='url'].theme-dark {
  color: #fff; }

.theme-light .ng-select .ng-select-container .ng-value {
  color: #565656 !important; }

:host-context(.theme-light) .ng-select .ng-select-container .ng-value {
  color: #565656 !important; }

.ng-select .ng-select-container .ng-value.theme-light {
  color: #565656 !important; }

.theme-dark .ng-select .ng-select-container .ng-value {
  color: #dadada !important; }

:host-context(.theme-dark) .ng-select .ng-select-container .ng-value {
  color: #dadada !important; }

.ng-select .ng-select-container .ng-value.theme-dark {
  color: #dadada !important; }

.theme-light .ng-select .ng-select-container .ng-value shared-editable-employee-label .label, .theme-light
.ng-select .ng-select-container .ng-value shared-task-tag .label {
  color: #565656; }

:host-context(.theme-light) .ng-select .ng-select-container .ng-value shared-editable-employee-label .label, :host-context(.theme-light)
.ng-select .ng-select-container .ng-value shared-task-tag .label {
  color: #565656; }

.ng-select .ng-select-container .ng-value shared-editable-employee-label .label.theme-light,
.ng-select .ng-select-container .ng-value shared-task-tag .label.theme-light {
  color: #565656; }

.theme-dark .ng-select .ng-select-container .ng-value shared-editable-employee-label .label, .theme-dark
.ng-select .ng-select-container .ng-value shared-task-tag .label {
  color: #dadada; }

:host-context(.theme-dark) .ng-select .ng-select-container .ng-value shared-editable-employee-label .label, :host-context(.theme-dark)
.ng-select .ng-select-container .ng-value shared-task-tag .label {
  color: #dadada; }

.ng-select .ng-select-container .ng-value shared-editable-employee-label .label.theme-dark,
.ng-select .ng-select-container .ng-value shared-task-tag .label.theme-dark {
  color: #dadada; }

.theme-light .ng-select .ng-select-container .ng-value-container .ng-placeholder, .theme-light
.ng-select .ng-select-container .ng-clear-wrapper, .theme-light
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: rgba(0, 0, 0, 0.54); }

:host-context(.theme-light) .ng-select .ng-select-container .ng-value-container .ng-placeholder, :host-context(.theme-light)
.ng-select .ng-select-container .ng-clear-wrapper, :host-context(.theme-light)
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: rgba(0, 0, 0, 0.54); }

.ng-select .ng-select-container .ng-value-container .ng-placeholder.theme-light,
.ng-select .ng-select-container .ng-clear-wrapper.theme-light,
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow.theme-light {
  color: rgba(0, 0, 0, 0.54); }

.theme-dark .ng-select .ng-select-container .ng-value-container .ng-placeholder, .theme-dark
.ng-select .ng-select-container .ng-clear-wrapper, .theme-dark
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: rgba(255, 255, 255, 0.54); }

:host-context(.theme-dark) .ng-select .ng-select-container .ng-value-container .ng-placeholder, :host-context(.theme-dark)
.ng-select .ng-select-container .ng-clear-wrapper, :host-context(.theme-dark)
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: rgba(255, 255, 255, 0.54); }

.ng-select .ng-select-container .ng-value-container .ng-placeholder.theme-dark,
.ng-select .ng-select-container .ng-clear-wrapper.theme-dark,
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow.theme-dark {
  color: rgba(255, 255, 255, 0.54); }

.theme-light .ng-select .ng-select-container:after {
  border-color: rgba(0, 0, 0, 0.54); }

:host-context(.theme-light) .ng-select .ng-select-container:after {
  border-color: rgba(0, 0, 0, 0.54); }

.ng-select .ng-select-container:after.theme-light {
  border-color: rgba(0, 0, 0, 0.54); }

.theme-dark .ng-select .ng-select-container:after {
  border-color: rgba(255, 255, 255, 0.54); }

:host-context(.theme-dark) .ng-select .ng-select-container:after {
  border-color: rgba(255, 255, 255, 0.54); }

.ng-select .ng-select-container:after.theme-dark {
  border-color: rgba(255, 255, 255, 0.54); }

.theme-light .clr-textarea, .theme-light
textarea {
  background-color: #fff;
  color: #565656; }

:host-context(.theme-light) .clr-textarea, :host-context(.theme-light)
textarea {
  background-color: #fff;
  color: #565656; }

.clr-textarea.theme-light,
textarea.theme-light {
  background-color: #fff;
  color: #565656; }

.theme-dark .clr-textarea, .theme-dark
textarea {
  background-color: #21242b;
  color: #dadada; }

:host-context(.theme-dark) .clr-textarea, :host-context(.theme-dark)
textarea {
  background-color: #21242b;
  color: #dadada; }

.clr-textarea.theme-dark,
textarea.theme-dark {
  background-color: #21242b;
  color: #dadada; }

.theme-light select[multiple], .theme-light
select[size] {
  background-color: #fff; }

:host-context(.theme-light) select[multiple], :host-context(.theme-light)
select[size] {
  background-color: #fff; }

select[multiple].theme-light,
select[size].theme-light {
  background-color: #fff; }

.theme-dark select[multiple], .theme-dark
select[size] {
  background-color: #21242b; }

:host-context(.theme-dark) select[multiple], :host-context(.theme-dark)
select[size] {
  background-color: #21242b; }

select[multiple].theme-dark,
select[size].theme-dark {
  background-color: #21242b; }

.theme-light select[multiple] option, .theme-light
select[size] option {
  color: #565656; }

:host-context(.theme-light) select[multiple] option, :host-context(.theme-light)
select[size] option {
  color: #565656; }

select[multiple] option.theme-light,
select[size] option.theme-light {
  color: #565656; }

.theme-dark select[multiple] option, .theme-dark
select[size] option {
  color: #dadada; }

:host-context(.theme-dark) select[multiple] option, :host-context(.theme-dark)
select[size] option {
  color: #dadada; }

select[multiple] option.theme-dark,
select[size] option.theme-dark {
  color: #dadada; }

.theme-light .card, .theme-light
.card .list-group-item {
  background-color: #fff;
  border-color: #a8a8a8; }

:host-context(.theme-light) .card, :host-context(.theme-light)
.card .list-group-item {
  background-color: #fff;
  border-color: #a8a8a8; }

.card.theme-light,
.card .list-group-item.theme-light {
  background-color: #fff;
  border-color: #a8a8a8; }

.theme-dark .card, .theme-dark
.card .list-group-item {
  background-color: #232932;
  border-color: #7e8998; }

:host-context(.theme-dark) .card, :host-context(.theme-dark)
.card .list-group-item {
  background-color: #232932;
  border-color: #7e8998; }

.card.theme-dark,
.card .list-group-item.theme-dark {
  background-color: #232932;
  border-color: #7e8998; }

.theme-light .card-header, .theme-light
.card-title {
  color: #000; }

:host-context(.theme-light) .card-header, :host-context(.theme-light)
.card-title {
  color: #000; }

.card-header.theme-light,
.card-title.theme-light {
  color: #000; }

.theme-dark .card-header, .theme-dark
.card-title {
  color: #fff; }

:host-context(.theme-dark) .card-header, :host-context(.theme-dark)
.card-title {
  color: #fff; }

.card-header.theme-dark,
.card-title.theme-dark {
  color: #fff; }

.card-block,
.card-header {
  border-bottom: 1px solid; }
  .theme-light .card-block, .theme-light
  .card-header {
    border-bottom-color: #a8a8a8; }
  :host-context(.theme-light) .card-block, :host-context(.theme-light)
  .card-header {
    border-bottom-color: #a8a8a8; }
  .card-block.theme-light,
  .card-header.theme-light {
    border-bottom-color: #a8a8a8; }
  .theme-dark .card-block, .theme-dark
  .card-header {
    border-bottom-color: #7e8998; }
  :host-context(.theme-dark) .card-block, :host-context(.theme-dark)
  .card-header {
    border-bottom-color: #7e8998; }
  .card-block.theme-dark,
  .card-header.theme-dark {
    border-bottom-color: #7e8998; }

.theme-light .datagrid, .theme-light
.datagrid-header .datagrid-row, .theme-light
.datagrid .datagrid-column, .theme-light
.datagrid-table .datagrid-column .datagrid-column-title, .theme-light
.datagrid-footer, .theme-light
.datagrid-table .datagrid-column .datagrid-filter, .theme-light
.datagrid-table .datagrid-placeholder, .theme-light
.datagrid-footer .column-switch-wrapper .column-switch {
  background-color: #fafafa;
  color: #565656;
  border-color: #ccc; }

:host-context(.theme-light) .datagrid, :host-context(.theme-light)
.datagrid-header .datagrid-row, :host-context(.theme-light)
.datagrid .datagrid-column, :host-context(.theme-light)
.datagrid-table .datagrid-column .datagrid-column-title, :host-context(.theme-light)
.datagrid-footer, :host-context(.theme-light)
.datagrid-table .datagrid-column .datagrid-filter, :host-context(.theme-light)
.datagrid-table .datagrid-placeholder, :host-context(.theme-light)
.datagrid-footer .column-switch-wrapper .column-switch {
  background-color: #fafafa;
  color: #565656;
  border-color: #ccc; }

.datagrid.theme-light,
.datagrid-header .datagrid-row.theme-light,
.datagrid .datagrid-column.theme-light,
.datagrid-table .datagrid-column .datagrid-column-title.theme-light,
.datagrid-footer.theme-light,
.datagrid-table .datagrid-column .datagrid-filter.theme-light,
.datagrid-table .datagrid-placeholder.theme-light,
.datagrid-footer .column-switch-wrapper .column-switch.theme-light {
  background-color: #fafafa;
  color: #565656;
  border-color: #ccc; }

.theme-dark .datagrid, .theme-dark
.datagrid-header .datagrid-row, .theme-dark
.datagrid .datagrid-column, .theme-dark
.datagrid-table .datagrid-column .datagrid-column-title, .theme-dark
.datagrid-footer, .theme-dark
.datagrid-table .datagrid-column .datagrid-filter, .theme-dark
.datagrid-table .datagrid-placeholder, .theme-dark
.datagrid-footer .column-switch-wrapper .column-switch {
  background-color: #21252a;
  color: #dadada;
  border-color: #dadada; }

:host-context(.theme-dark) .datagrid, :host-context(.theme-dark)
.datagrid-header .datagrid-row, :host-context(.theme-dark)
.datagrid .datagrid-column, :host-context(.theme-dark)
.datagrid-table .datagrid-column .datagrid-column-title, :host-context(.theme-dark)
.datagrid-footer, :host-context(.theme-dark)
.datagrid-table .datagrid-column .datagrid-filter, :host-context(.theme-dark)
.datagrid-table .datagrid-placeholder, :host-context(.theme-dark)
.datagrid-footer .column-switch-wrapper .column-switch {
  background-color: #21252a;
  color: #dadada;
  border-color: #dadada; }

.datagrid.theme-dark,
.datagrid-header .datagrid-row.theme-dark,
.datagrid .datagrid-column.theme-dark,
.datagrid-table .datagrid-column .datagrid-column-title.theme-dark,
.datagrid-footer.theme-dark,
.datagrid-table .datagrid-column .datagrid-filter.theme-dark,
.datagrid-table .datagrid-placeholder.theme-dark,
.datagrid-footer .column-switch-wrapper .column-switch.theme-dark {
  background-color: #21252a;
  color: #dadada;
  border-color: #dadada; }

.theme-light .datagrid-row:hover {
  background-color: #eee; }

:host-context(.theme-light) .datagrid-row:hover {
  background-color: #eee; }

.datagrid-row:hover.theme-light {
  background-color: #eee; }

.theme-dark .datagrid-row:hover {
  background-color: #0f1113; }

:host-context(.theme-dark) .datagrid-row:hover {
  background-color: #0f1113; }

.datagrid-row:hover.theme-dark {
  background-color: #0f1113; }

.theme-light .datagrid-row.datagrid-selected {
  background-color: #d9e4ea;
  color: #fff; }

:host-context(.theme-light) .datagrid-row.datagrid-selected {
  background-color: #d9e4ea;
  color: #fff; }

.datagrid-row.datagrid-selected.theme-light {
  background-color: #d9e4ea;
  color: #fff; }

.theme-dark .datagrid-row.datagrid-selected {
  background-color: #000;
  color: #dadada; }

:host-context(.theme-dark) .datagrid-row.datagrid-selected {
  background-color: #000;
  color: #dadada; }

.datagrid-row.datagrid-selected.theme-dark {
  background-color: #000;
  color: #dadada; }

.theme-light .datagrid-spinner {
  background-color: rgba(255, 255, 255, 0.6); }

:host-context(.theme-light) .datagrid-spinner {
  background-color: rgba(255, 255, 255, 0.6); }

.datagrid-spinner.theme-light {
  background-color: rgba(255, 255, 255, 0.6); }

.theme-dark .datagrid-spinner {
  background-color: rgba(33, 36, 43, 0.6); }

:host-context(.theme-dark) .datagrid-spinner {
  background-color: rgba(33, 36, 43, 0.6); }

.datagrid-spinner.theme-dark {
  background-color: rgba(33, 36, 43, 0.6); }

.theme-light .table {
  color: #565656;
  background-color: #fff; }

:host-context(.theme-light) .table {
  color: #565656;
  background-color: #fff; }

.table.theme-light {
  color: #565656;
  background-color: #fff; }

.theme-dark .table {
  color: #dadada;
  background-color: #21242b; }

:host-context(.theme-dark) .table {
  color: #dadada;
  background-color: #21242b; }

.table.theme-dark {
  color: #dadada;
  background-color: #21242b; }

.theme-light .table th {
  background-color: #fafafa;
  color: #565656; }

:host-context(.theme-light) .table th {
  background-color: #fafafa;
  color: #565656; }

.table th.theme-light {
  background-color: #fafafa;
  color: #565656; }

.theme-dark .table th {
  background-color: #21252a;
  color: #dadada; }

:host-context(.theme-dark) .table th {
  background-color: #21252a;
  color: #dadada; }

.table th.theme-dark {
  background-color: #21252a;
  color: #dadada; }

.theme-light .dropdown-menu {
  background-color: #fff; }

:host-context(.theme-light) .dropdown-menu {
  background-color: #fff; }

.dropdown-menu.theme-light {
  background-color: #fff; }

.theme-dark .dropdown-menu {
  background-color: #21242b; }

:host-context(.theme-dark) .dropdown-menu {
  background-color: #21242b; }

.dropdown-menu.theme-dark {
  background-color: #21242b; }

.theme-light .dropdown-menu .btn, .theme-light
.dropdown-menu .btn-danger, .theme-light
.dropdown-menu .btn-info, .theme-light
.dropdown-menu .btn-link, .theme-light
.dropdown-menu .btn-outline, .theme-light
.dropdown-menu .btn-outline-danger, .theme-light
.dropdown-menu .btn-outline-primary, .theme-light
.dropdown-menu .btn-outline-secondary, .theme-light
.dropdown-menu .btn-outline-success, .theme-light
.dropdown-menu .btn-outline-warning, .theme-light
.dropdown-menu .btn-primary, .theme-light
.dropdown-menu .btn-secondary, .theme-light
.dropdown-menu .btn-success, .theme-light
.dropdown-menu .btn-warning, .theme-light
.dropdown-menu .dropdown-item {
  color: #565656; }

:host-context(.theme-light) .dropdown-menu .btn, :host-context(.theme-light)
.dropdown-menu .btn-danger, :host-context(.theme-light)
.dropdown-menu .btn-info, :host-context(.theme-light)
.dropdown-menu .btn-link, :host-context(.theme-light)
.dropdown-menu .btn-outline, :host-context(.theme-light)
.dropdown-menu .btn-outline-danger, :host-context(.theme-light)
.dropdown-menu .btn-outline-primary, :host-context(.theme-light)
.dropdown-menu .btn-outline-secondary, :host-context(.theme-light)
.dropdown-menu .btn-outline-success, :host-context(.theme-light)
.dropdown-menu .btn-outline-warning, :host-context(.theme-light)
.dropdown-menu .btn-primary, :host-context(.theme-light)
.dropdown-menu .btn-secondary, :host-context(.theme-light)
.dropdown-menu .btn-success, :host-context(.theme-light)
.dropdown-menu .btn-warning, :host-context(.theme-light)
.dropdown-menu .dropdown-item {
  color: #565656; }

.dropdown-menu .btn.theme-light,
.dropdown-menu .btn-danger.theme-light,
.dropdown-menu .btn-info.theme-light,
.dropdown-menu .btn-link.theme-light,
.dropdown-menu .btn-outline.theme-light,
.dropdown-menu .btn-outline-danger.theme-light,
.dropdown-menu .btn-outline-primary.theme-light,
.dropdown-menu .btn-outline-secondary.theme-light,
.dropdown-menu .btn-outline-success.theme-light,
.dropdown-menu .btn-outline-warning.theme-light,
.dropdown-menu .btn-primary.theme-light,
.dropdown-menu .btn-secondary.theme-light,
.dropdown-menu .btn-success.theme-light,
.dropdown-menu .btn-warning.theme-light,
.dropdown-menu .dropdown-item.theme-light {
  color: #565656; }

.theme-dark .dropdown-menu .btn, .theme-dark
.dropdown-menu .btn-danger, .theme-dark
.dropdown-menu .btn-info, .theme-dark
.dropdown-menu .btn-link, .theme-dark
.dropdown-menu .btn-outline, .theme-dark
.dropdown-menu .btn-outline-danger, .theme-dark
.dropdown-menu .btn-outline-primary, .theme-dark
.dropdown-menu .btn-outline-secondary, .theme-dark
.dropdown-menu .btn-outline-success, .theme-dark
.dropdown-menu .btn-outline-warning, .theme-dark
.dropdown-menu .btn-primary, .theme-dark
.dropdown-menu .btn-secondary, .theme-dark
.dropdown-menu .btn-success, .theme-dark
.dropdown-menu .btn-warning, .theme-dark
.dropdown-menu .dropdown-item {
  color: #dadada; }

:host-context(.theme-dark) .dropdown-menu .btn, :host-context(.theme-dark)
.dropdown-menu .btn-danger, :host-context(.theme-dark)
.dropdown-menu .btn-info, :host-context(.theme-dark)
.dropdown-menu .btn-link, :host-context(.theme-dark)
.dropdown-menu .btn-outline, :host-context(.theme-dark)
.dropdown-menu .btn-outline-danger, :host-context(.theme-dark)
.dropdown-menu .btn-outline-primary, :host-context(.theme-dark)
.dropdown-menu .btn-outline-secondary, :host-context(.theme-dark)
.dropdown-menu .btn-outline-success, :host-context(.theme-dark)
.dropdown-menu .btn-outline-warning, :host-context(.theme-dark)
.dropdown-menu .btn-primary, :host-context(.theme-dark)
.dropdown-menu .btn-secondary, :host-context(.theme-dark)
.dropdown-menu .btn-success, :host-context(.theme-dark)
.dropdown-menu .btn-warning, :host-context(.theme-dark)
.dropdown-menu .dropdown-item {
  color: #dadada; }

.dropdown-menu .btn.theme-dark,
.dropdown-menu .btn-danger.theme-dark,
.dropdown-menu .btn-info.theme-dark,
.dropdown-menu .btn-link.theme-dark,
.dropdown-menu .btn-outline.theme-dark,
.dropdown-menu .btn-outline-danger.theme-dark,
.dropdown-menu .btn-outline-primary.theme-dark,
.dropdown-menu .btn-outline-secondary.theme-dark,
.dropdown-menu .btn-outline-success.theme-dark,
.dropdown-menu .btn-outline-warning.theme-dark,
.dropdown-menu .btn-primary.theme-dark,
.dropdown-menu .btn-secondary.theme-dark,
.dropdown-menu .btn-success.theme-dark,
.dropdown-menu .btn-warning.theme-dark,
.dropdown-menu .dropdown-item.theme-dark {
  color: #dadada; }

.theme-light .dropdown-menu .btn-danger:focus, .theme-light
.dropdown-menu .btn-danger:hover, .theme-light
.dropdown-menu .btn-info:focus, .theme-light
.dropdown-menu .btn-info:hover, .theme-light
.dropdown-menu .btn-link:focus, .theme-light
.dropdown-menu .btn-link:hover, .theme-light
.dropdown-menu .btn-outline-danger:focus, .theme-light
.dropdown-menu .btn-outline-danger:hover, .theme-light
.dropdown-menu .btn-outline-primary:focus, .theme-light
.dropdown-menu .btn-outline-primary:hover, .theme-light
.dropdown-menu .btn-outline-secondary:focus, .theme-light
.dropdown-menu .btn-outline-secondary:hover, .theme-light
.dropdown-menu .btn-outline-success:focus, .theme-light
.dropdown-menu .btn-outline-success:hover, .theme-light
.dropdown-menu .btn-outline-warning:focus, .theme-light
.dropdown-menu .btn-outline-warning:hover, .theme-light
.dropdown-menu .btn-outline:focus, .theme-light
.dropdown-menu .btn-outline:hover, .theme-light
.dropdown-menu .btn-primary:focus, .theme-light
.dropdown-menu .btn-primary:hover, .theme-light
.dropdown-menu .btn-secondary:focus, .theme-light
.dropdown-menu .btn-secondary:hover, .theme-light
.dropdown-menu .btn-success:focus, .theme-light
.dropdown-menu .btn-success:hover, .theme-light
.dropdown-menu .btn-warning:focus, .theme-light
.dropdown-menu .btn-warning:hover, .theme-light
.dropdown-menu .btn:focus, .theme-light
.dropdown-menu .btn:hover, .theme-light
.dropdown-menu .dropdown-item:focus, .theme-light
.dropdown-menu .dropdown-item:hover {
  background-color: #eee; }

:host-context(.theme-light) .dropdown-menu .btn-danger:focus, :host-context(.theme-light)
.dropdown-menu .btn-danger:hover, :host-context(.theme-light)
.dropdown-menu .btn-info:focus, :host-context(.theme-light)
.dropdown-menu .btn-info:hover, :host-context(.theme-light)
.dropdown-menu .btn-link:focus, :host-context(.theme-light)
.dropdown-menu .btn-link:hover, :host-context(.theme-light)
.dropdown-menu .btn-outline-danger:focus, :host-context(.theme-light)
.dropdown-menu .btn-outline-danger:hover, :host-context(.theme-light)
.dropdown-menu .btn-outline-primary:focus, :host-context(.theme-light)
.dropdown-menu .btn-outline-primary:hover, :host-context(.theme-light)
.dropdown-menu .btn-outline-secondary:focus, :host-context(.theme-light)
.dropdown-menu .btn-outline-secondary:hover, :host-context(.theme-light)
.dropdown-menu .btn-outline-success:focus, :host-context(.theme-light)
.dropdown-menu .btn-outline-success:hover, :host-context(.theme-light)
.dropdown-menu .btn-outline-warning:focus, :host-context(.theme-light)
.dropdown-menu .btn-outline-warning:hover, :host-context(.theme-light)
.dropdown-menu .btn-outline:focus, :host-context(.theme-light)
.dropdown-menu .btn-outline:hover, :host-context(.theme-light)
.dropdown-menu .btn-primary:focus, :host-context(.theme-light)
.dropdown-menu .btn-primary:hover, :host-context(.theme-light)
.dropdown-menu .btn-secondary:focus, :host-context(.theme-light)
.dropdown-menu .btn-secondary:hover, :host-context(.theme-light)
.dropdown-menu .btn-success:focus, :host-context(.theme-light)
.dropdown-menu .btn-success:hover, :host-context(.theme-light)
.dropdown-menu .btn-warning:focus, :host-context(.theme-light)
.dropdown-menu .btn-warning:hover, :host-context(.theme-light)
.dropdown-menu .btn:focus, :host-context(.theme-light)
.dropdown-menu .btn:hover, :host-context(.theme-light)
.dropdown-menu .dropdown-item:focus, :host-context(.theme-light)
.dropdown-menu .dropdown-item:hover {
  background-color: #eee; }

.dropdown-menu .btn-danger:focus.theme-light,
.dropdown-menu .btn-danger:hover.theme-light,
.dropdown-menu .btn-info:focus.theme-light,
.dropdown-menu .btn-info:hover.theme-light,
.dropdown-menu .btn-link:focus.theme-light,
.dropdown-menu .btn-link:hover.theme-light,
.dropdown-menu .btn-outline-danger:focus.theme-light,
.dropdown-menu .btn-outline-danger:hover.theme-light,
.dropdown-menu .btn-outline-primary:focus.theme-light,
.dropdown-menu .btn-outline-primary:hover.theme-light,
.dropdown-menu .btn-outline-secondary:focus.theme-light,
.dropdown-menu .btn-outline-secondary:hover.theme-light,
.dropdown-menu .btn-outline-success:focus.theme-light,
.dropdown-menu .btn-outline-success:hover.theme-light,
.dropdown-menu .btn-outline-warning:focus.theme-light,
.dropdown-menu .btn-outline-warning:hover.theme-light,
.dropdown-menu .btn-outline:focus.theme-light,
.dropdown-menu .btn-outline:hover.theme-light,
.dropdown-menu .btn-primary:focus.theme-light,
.dropdown-menu .btn-primary:hover.theme-light,
.dropdown-menu .btn-secondary:focus.theme-light,
.dropdown-menu .btn-secondary:hover.theme-light,
.dropdown-menu .btn-success:focus.theme-light,
.dropdown-menu .btn-success:hover.theme-light,
.dropdown-menu .btn-warning:focus.theme-light,
.dropdown-menu .btn-warning:hover.theme-light,
.dropdown-menu .btn:focus.theme-light,
.dropdown-menu .btn:hover.theme-light,
.dropdown-menu .dropdown-item:focus.theme-light,
.dropdown-menu .dropdown-item:hover.theme-light {
  background-color: #eee; }

.theme-dark .dropdown-menu .btn-danger:focus, .theme-dark
.dropdown-menu .btn-danger:hover, .theme-dark
.dropdown-menu .btn-info:focus, .theme-dark
.dropdown-menu .btn-info:hover, .theme-dark
.dropdown-menu .btn-link:focus, .theme-dark
.dropdown-menu .btn-link:hover, .theme-dark
.dropdown-menu .btn-outline-danger:focus, .theme-dark
.dropdown-menu .btn-outline-danger:hover, .theme-dark
.dropdown-menu .btn-outline-primary:focus, .theme-dark
.dropdown-menu .btn-outline-primary:hover, .theme-dark
.dropdown-menu .btn-outline-secondary:focus, .theme-dark
.dropdown-menu .btn-outline-secondary:hover, .theme-dark
.dropdown-menu .btn-outline-success:focus, .theme-dark
.dropdown-menu .btn-outline-success:hover, .theme-dark
.dropdown-menu .btn-outline-warning:focus, .theme-dark
.dropdown-menu .btn-outline-warning:hover, .theme-dark
.dropdown-menu .btn-outline:focus, .theme-dark
.dropdown-menu .btn-outline:hover, .theme-dark
.dropdown-menu .btn-primary:focus, .theme-dark
.dropdown-menu .btn-primary:hover, .theme-dark
.dropdown-menu .btn-secondary:focus, .theme-dark
.dropdown-menu .btn-secondary:hover, .theme-dark
.dropdown-menu .btn-success:focus, .theme-dark
.dropdown-menu .btn-success:hover, .theme-dark
.dropdown-menu .btn-warning:focus, .theme-dark
.dropdown-menu .btn-warning:hover, .theme-dark
.dropdown-menu .btn:focus, .theme-dark
.dropdown-menu .btn:hover, .theme-dark
.dropdown-menu .dropdown-item:focus, .theme-dark
.dropdown-menu .dropdown-item:hover {
  background-color: #303d4f; }

:host-context(.theme-dark) .dropdown-menu .btn-danger:focus, :host-context(.theme-dark)
.dropdown-menu .btn-danger:hover, :host-context(.theme-dark)
.dropdown-menu .btn-info:focus, :host-context(.theme-dark)
.dropdown-menu .btn-info:hover, :host-context(.theme-dark)
.dropdown-menu .btn-link:focus, :host-context(.theme-dark)
.dropdown-menu .btn-link:hover, :host-context(.theme-dark)
.dropdown-menu .btn-outline-danger:focus, :host-context(.theme-dark)
.dropdown-menu .btn-outline-danger:hover, :host-context(.theme-dark)
.dropdown-menu .btn-outline-primary:focus, :host-context(.theme-dark)
.dropdown-menu .btn-outline-primary:hover, :host-context(.theme-dark)
.dropdown-menu .btn-outline-secondary:focus, :host-context(.theme-dark)
.dropdown-menu .btn-outline-secondary:hover, :host-context(.theme-dark)
.dropdown-menu .btn-outline-success:focus, :host-context(.theme-dark)
.dropdown-menu .btn-outline-success:hover, :host-context(.theme-dark)
.dropdown-menu .btn-outline-warning:focus, :host-context(.theme-dark)
.dropdown-menu .btn-outline-warning:hover, :host-context(.theme-dark)
.dropdown-menu .btn-outline:focus, :host-context(.theme-dark)
.dropdown-menu .btn-outline:hover, :host-context(.theme-dark)
.dropdown-menu .btn-primary:focus, :host-context(.theme-dark)
.dropdown-menu .btn-primary:hover, :host-context(.theme-dark)
.dropdown-menu .btn-secondary:focus, :host-context(.theme-dark)
.dropdown-menu .btn-secondary:hover, :host-context(.theme-dark)
.dropdown-menu .btn-success:focus, :host-context(.theme-dark)
.dropdown-menu .btn-success:hover, :host-context(.theme-dark)
.dropdown-menu .btn-warning:focus, :host-context(.theme-dark)
.dropdown-menu .btn-warning:hover, :host-context(.theme-dark)
.dropdown-menu .btn:focus, :host-context(.theme-dark)
.dropdown-menu .btn:hover, :host-context(.theme-dark)
.dropdown-menu .dropdown-item:focus, :host-context(.theme-dark)
.dropdown-menu .dropdown-item:hover {
  background-color: #303d4f; }

.dropdown-menu .btn-danger:focus.theme-dark,
.dropdown-menu .btn-danger:hover.theme-dark,
.dropdown-menu .btn-info:focus.theme-dark,
.dropdown-menu .btn-info:hover.theme-dark,
.dropdown-menu .btn-link:focus.theme-dark,
.dropdown-menu .btn-link:hover.theme-dark,
.dropdown-menu .btn-outline-danger:focus.theme-dark,
.dropdown-menu .btn-outline-danger:hover.theme-dark,
.dropdown-menu .btn-outline-primary:focus.theme-dark,
.dropdown-menu .btn-outline-primary:hover.theme-dark,
.dropdown-menu .btn-outline-secondary:focus.theme-dark,
.dropdown-menu .btn-outline-secondary:hover.theme-dark,
.dropdown-menu .btn-outline-success:focus.theme-dark,
.dropdown-menu .btn-outline-success:hover.theme-dark,
.dropdown-menu .btn-outline-warning:focus.theme-dark,
.dropdown-menu .btn-outline-warning:hover.theme-dark,
.dropdown-menu .btn-outline:focus.theme-dark,
.dropdown-menu .btn-outline:hover.theme-dark,
.dropdown-menu .btn-primary:focus.theme-dark,
.dropdown-menu .btn-primary:hover.theme-dark,
.dropdown-menu .btn-secondary:focus.theme-dark,
.dropdown-menu .btn-secondary:hover.theme-dark,
.dropdown-menu .btn-success:focus.theme-dark,
.dropdown-menu .btn-success:hover.theme-dark,
.dropdown-menu .btn-warning:focus.theme-dark,
.dropdown-menu .btn-warning:hover.theme-dark,
.dropdown-menu .btn:focus.theme-dark,
.dropdown-menu .btn:hover.theme-dark,
.dropdown-menu .dropdown-item:focus.theme-dark,
.dropdown-menu .dropdown-item:hover.theme-dark {
  background-color: #303d4f; }

.theme-light .clr-input {
  color: #000; }

:host-context(.theme-light) .clr-input {
  color: #000; }

.clr-input.theme-light {
  color: #000; }

.theme-dark .clr-input {
  color: #fff; }

:host-context(.theme-dark) .clr-input {
  color: #fff; }

.clr-input.theme-dark {
  color: #fff; }

.theme-light clr-control-helper.clr-subtext {
  color: #737373; }

:host-context(.theme-light) clr-control-helper.clr-subtext {
  color: #737373; }

clr-control-helper.clr-subtext.theme-light {
  color: #737373; }

.theme-dark clr-control-helper.clr-subtext {
  color: #fff; }

:host-context(.theme-dark) clr-control-helper.clr-subtext {
  color: #fff; }

clr-control-helper.clr-subtext.theme-dark {
  color: #fff; }

.theme-light clr-wizard.clr-wizard .clr-wizard-stepnav-wrapper {
  background-color: #fafafa; }

:host-context(.theme-light) clr-wizard.clr-wizard .clr-wizard-stepnav-wrapper {
  background-color: #fafafa; }

clr-wizard.clr-wizard .clr-wizard-stepnav-wrapper.theme-light {
  background-color: #fafafa; }

.theme-dark clr-wizard.clr-wizard .clr-wizard-stepnav-wrapper {
  background-color: #21242b; }

:host-context(.theme-dark) clr-wizard.clr-wizard .clr-wizard-stepnav-wrapper {
  background-color: #21242b; }

clr-wizard.clr-wizard .clr-wizard-stepnav-wrapper.theme-dark {
  background-color: #21242b; }

.theme-light clr-wizard.clr-wizard .clr-wizard-title {
  color: #313131; }

:host-context(.theme-light) clr-wizard.clr-wizard .clr-wizard-title {
  color: #313131; }

clr-wizard.clr-wizard .clr-wizard-title.theme-light {
  color: #313131; }

.theme-dark clr-wizard.clr-wizard .clr-wizard-title {
  color: #f5f5f5; }

:host-context(.theme-dark) clr-wizard.clr-wizard .clr-wizard-title {
  color: #f5f5f5; }

clr-wizard.clr-wizard .clr-wizard-title.theme-dark {
  color: #f5f5f5; }

.theme-light clr-wizard.clr-wizard .clr-wizard-stepnav-item:not(.disabled) {
  color: #565656; }

:host-context(.theme-light) clr-wizard.clr-wizard .clr-wizard-stepnav-item:not(.disabled) {
  color: #565656; }

clr-wizard.clr-wizard .clr-wizard-stepnav-item:not(.disabled).theme-light {
  color: #565656; }

.theme-dark clr-wizard.clr-wizard .clr-wizard-stepnav-item:not(.disabled) {
  color: #dadada; }

:host-context(.theme-dark) clr-wizard.clr-wizard .clr-wizard-stepnav-item:not(.disabled) {
  color: #dadada; }

clr-wizard.clr-wizard .clr-wizard-stepnav-item:not(.disabled).theme-dark {
  color: #dadada; }

.theme-light clr-wizard.clr-wizard .clr-wizard-stepnav-item.active {
  color: #313131; }

:host-context(.theme-light) clr-wizard.clr-wizard .clr-wizard-stepnav-item.active {
  color: #313131; }

clr-wizard.clr-wizard .clr-wizard-stepnav-item.active.theme-light {
  color: #313131; }

.theme-dark clr-wizard.clr-wizard .clr-wizard-stepnav-item.active {
  color: #f5f5f5; }

:host-context(.theme-dark) clr-wizard.clr-wizard .clr-wizard-stepnav-item.active {
  color: #f5f5f5; }

clr-wizard.clr-wizard .clr-wizard-stepnav-item.active.theme-dark {
  color: #f5f5f5; }

.theme-light clr-wizard.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link {
  background-color: #d9e4ea; }

:host-context(.theme-light) clr-wizard.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link {
  background-color: #d9e4ea; }

clr-wizard.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link.theme-light {
  background-color: #d9e4ea; }

.theme-dark clr-wizard.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link {
  background-color: #0092e2; }

:host-context(.theme-dark) clr-wizard.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link {
  background-color: #0092e2; }

clr-wizard.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link.theme-dark {
  background-color: #0092e2; }

.theme-light .dropdown-menu .dropdown-header {
  color: #313131; }

:host-context(.theme-light) .dropdown-menu .dropdown-header {
  color: #313131; }

.dropdown-menu .dropdown-header.theme-light {
  color: #313131; }

.theme-dark .dropdown-menu .dropdown-header {
  color: #f5f5f5; }

:host-context(.theme-dark) .dropdown-menu .dropdown-header {
  color: #f5f5f5; }

.dropdown-menu .dropdown-header.theme-dark {
  color: #f5f5f5; }

.theme-light .clr-checkbox-wrapper label, .theme-light
.clr-radio-wrapper label {
  color: #565656; }

:host-context(.theme-light) .clr-checkbox-wrapper label, :host-context(.theme-light)
.clr-radio-wrapper label {
  color: #565656; }

.clr-checkbox-wrapper label.theme-light,
.clr-radio-wrapper label.theme-light {
  color: #565656; }

.theme-dark .clr-checkbox-wrapper label, .theme-dark
.clr-radio-wrapper label {
  color: #dadada; }

:host-context(.theme-dark) .clr-checkbox-wrapper label, :host-context(.theme-dark)
.clr-radio-wrapper label {
  color: #dadada; }

.clr-checkbox-wrapper label.theme-dark,
.clr-radio-wrapper label.theme-dark {
  color: #dadada; }

.theme-light clr-stack-view .stack-view {
  color: #565656;
  background-color: #fff; }

:host-context(.theme-light) clr-stack-view .stack-view {
  color: #565656;
  background-color: #fff; }

clr-stack-view .stack-view.theme-light {
  color: #565656;
  background-color: #fff; }

.theme-dark clr-stack-view .stack-view {
  color: #dadada;
  background-color: #21242b; }

:host-context(.theme-dark) clr-stack-view .stack-view {
  color: #dadada;
  background-color: #21242b; }

clr-stack-view .stack-view.theme-dark {
  color: #dadada;
  background-color: #21242b; }

.theme-light clr-stack-view .stack-view .stack-block-content, .theme-light
clr-stack-view .stack-view .stack-block-label {
  color: #565656;
  background-color: #fafafa; }

:host-context(.theme-light) clr-stack-view .stack-view .stack-block-content, :host-context(.theme-light)
clr-stack-view .stack-view .stack-block-label {
  color: #565656;
  background-color: #fafafa; }

clr-stack-view .stack-view .stack-block-content.theme-light,
clr-stack-view .stack-view .stack-block-label.theme-light {
  color: #565656;
  background-color: #fafafa; }

.theme-dark clr-stack-view .stack-view .stack-block-content, .theme-dark
clr-stack-view .stack-view .stack-block-label {
  color: #dadada;
  background-color: #21242b; }

:host-context(.theme-dark) clr-stack-view .stack-view .stack-block-content, :host-context(.theme-dark)
clr-stack-view .stack-view .stack-block-label {
  color: #dadada;
  background-color: #21242b; }

clr-stack-view .stack-view .stack-block-content.theme-dark,
clr-stack-view .stack-view .stack-block-label.theme-dark {
  color: #dadada;
  background-color: #21242b; }

.theme-light clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-content, .theme-light
clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-label, .theme-light
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-content, .theme-light
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-label {
  background-color: #eee; }

:host-context(.theme-light) clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-content, :host-context(.theme-light)
clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-label, :host-context(.theme-light)
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-content, :host-context(.theme-light)
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-label {
  background-color: #eee; }

clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-content.theme-light,
clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-label.theme-light,
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-content.theme-light,
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-label.theme-light {
  background-color: #eee; }

.theme-dark clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-content, .theme-dark
clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-label, .theme-dark
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-content, .theme-dark
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-label {
  background-color: #21242b; }

:host-context(.theme-dark) clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-content, :host-context(.theme-dark)
clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-label, :host-context(.theme-dark)
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-content, :host-context(.theme-dark)
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-label {
  background-color: #21242b; }

clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-content.theme-dark,
clr-stack-view .stack-view .stack-block-expandable.on-focus:not(.stack-block-expanded) > .stack-block-label.theme-dark,
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-content.theme-dark,
clr-stack-view .stack-view .stack-block-expandable:hover:not(.stack-block-expanded) > .stack-block-label.theme-dark {
  background-color: #21242b; }

.theme-light a:visited {
  color: #5659b9; }

:host-context(.theme-light) a:visited {
  color: #5659b9; }

a:visited.theme-light {
  color: #5659b9; }

.theme-dark a:visited {
  color: #4dc2df; }

:host-context(.theme-dark) a:visited {
  color: #4dc2df; }

a:visited.theme-dark {
  color: #4dc2df; }

.clr-col-no-basis {
  flex-basis: unset;
  width: 0; }

.theme-light .btn-info .btn, .theme-light
.btn-outline-info .btn, .theme-light
.btn-outline-primary .btn, .theme-light
.btn-outline-secondary .btn, .theme-light
.btn-primary-outline .btn, .theme-light
.btn-secondary .btn, .theme-light
.btn-secondary-outline .btn, .theme-light
.btn.btn-info, .theme-light
.btn.btn-outline-primary, .theme-light
.btn.btn-outline-secondary, .theme-light
.btn.btn-primary-outline, .theme-light
.btn.btn-secondary, .theme-light
.btn.btn-secondary-outline {
  color: #0079b8;
  border-color: #0079b8; }

:host-context(.theme-light) .btn-info .btn, :host-context(.theme-light)
.btn-outline-info .btn, :host-context(.theme-light)
.btn-outline-primary .btn, :host-context(.theme-light)
.btn-outline-secondary .btn, :host-context(.theme-light)
.btn-primary-outline .btn, :host-context(.theme-light)
.btn-secondary .btn, :host-context(.theme-light)
.btn-secondary-outline .btn, :host-context(.theme-light)
.btn.btn-info, :host-context(.theme-light)
.btn.btn-outline-primary, :host-context(.theme-light)
.btn.btn-outline-secondary, :host-context(.theme-light)
.btn.btn-primary-outline, :host-context(.theme-light)
.btn.btn-secondary, :host-context(.theme-light)
.btn.btn-secondary-outline {
  color: #0079b8;
  border-color: #0079b8; }

.btn-info .btn.theme-light,
.btn-outline-info .btn.theme-light,
.btn-outline-primary .btn.theme-light,
.btn-outline-secondary .btn.theme-light,
.btn-primary-outline .btn.theme-light,
.btn-secondary .btn.theme-light,
.btn-secondary-outline .btn.theme-light,
.btn.btn-info.theme-light,
.btn.btn-outline-primary.theme-light,
.btn.btn-outline-secondary.theme-light,
.btn.btn-primary-outline.theme-light,
.btn.btn-secondary.theme-light,
.btn.btn-secondary-outline.theme-light {
  color: #0079b8;
  border-color: #0079b8; }

.theme-dark .btn-info .btn, .theme-dark
.btn-outline-info .btn, .theme-dark
.btn-outline-primary .btn, .theme-dark
.btn-outline-secondary .btn, .theme-dark
.btn-primary-outline .btn, .theme-dark
.btn-secondary .btn, .theme-dark
.btn-secondary-outline .btn, .theme-dark
.btn.btn-info, .theme-dark
.btn.btn-outline-primary, .theme-dark
.btn.btn-outline-secondary, .theme-dark
.btn.btn-primary-outline, .theme-dark
.btn.btn-secondary, .theme-dark
.btn.btn-secondary-outline {
  color: #0092e0;
  border-color: #0092e0; }

:host-context(.theme-dark) .btn-info .btn, :host-context(.theme-dark)
.btn-outline-info .btn, :host-context(.theme-dark)
.btn-outline-primary .btn, :host-context(.theme-dark)
.btn-outline-secondary .btn, :host-context(.theme-dark)
.btn-primary-outline .btn, :host-context(.theme-dark)
.btn-secondary .btn, :host-context(.theme-dark)
.btn-secondary-outline .btn, :host-context(.theme-dark)
.btn.btn-info, :host-context(.theme-dark)
.btn.btn-outline-primary, :host-context(.theme-dark)
.btn.btn-outline-secondary, :host-context(.theme-dark)
.btn.btn-primary-outline, :host-context(.theme-dark)
.btn.btn-secondary, :host-context(.theme-dark)
.btn.btn-secondary-outline {
  color: #0092e0;
  border-color: #0092e0; }

.btn-info .btn.theme-dark,
.btn-outline-info .btn.theme-dark,
.btn-outline-primary .btn.theme-dark,
.btn-outline-secondary .btn.theme-dark,
.btn-primary-outline .btn.theme-dark,
.btn-secondary .btn.theme-dark,
.btn-secondary-outline .btn.theme-dark,
.btn.btn-info.theme-dark,
.btn.btn-outline-primary.theme-dark,
.btn.btn-outline-secondary.theme-dark,
.btn.btn-primary-outline.theme-dark,
.btn.btn-secondary.theme-dark,
.btn.btn-secondary-outline.theme-dark {
  color: #0092e0;
  border-color: #0092e0; }

.btn.active {
  color: #fff !important; }

optgroup {
  color: #21242b; }

.theme-light app-projects-list .datagrid-table clr-signpost clr-icon {
  color: #565656; }

:host-context(.theme-light) app-projects-list .datagrid-table clr-signpost clr-icon {
  color: #565656; }

app-projects-list .datagrid-table clr-signpost clr-icon.theme-light {
  color: #565656; }

.theme-dark app-projects-list .datagrid-table clr-signpost clr-icon {
  color: #dadada; }

:host-context(.theme-dark) app-projects-list .datagrid-table clr-signpost clr-icon {
  color: #dadada; }

app-projects-list .datagrid-table clr-signpost clr-icon.theme-dark {
  color: #dadada; }

.theme-light .datagrid-footer .column-switch-wrapper .column-switch .switch-footer .btn {
  color: #565656; }

:host-context(.theme-light) .datagrid-footer .column-switch-wrapper .column-switch .switch-footer .btn {
  color: #565656; }

.datagrid-footer .column-switch-wrapper .column-switch .switch-footer .btn.theme-light {
  color: #565656; }

.theme-dark .datagrid-footer .column-switch-wrapper .column-switch .switch-footer .btn {
  color: #dadada; }

:host-context(.theme-dark) .datagrid-footer .column-switch-wrapper .column-switch .switch-footer .btn {
  color: #dadada; }

.datagrid-footer .column-switch-wrapper .column-switch .switch-footer .btn.theme-dark {
  color: #dadada; }

app-board-detail-container p {
  font-size: 0.7rem !important; }

app-board-detail-main .clr-control-container {
  width: 100%; }

/* .dg-drag { 
    //Class appears on element when its draggable
} */
.dg-wrapper {
  position: static;
  width: 0;
  height: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none; }

.dg-controls {
  display: inline-block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 190;
  border: 1px dashed #32b5fe;
  cursor: move; }

.dg-hdl {
  cursor: pointer;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 1px solid #32b5fe;
  margin-top: -6px;
  margin-left: -6px; }

.dg,
.dg-controls,
.dg-hdl {
  position: absolute;
  z-index: 199; }

.dg-hdl-t {
  top: 0; }

.dg-hdl-m {
  top: 50%; }

.dg-hdl-b {
  top: calc(100% + 5px) !important; }

.dg-hdl-l {
  left: -5px; }

.dg-hdl-c {
  left: 50%;
  top: -5px; }

.dg-hdl-r {
  left: calc(100% + 5px); }

.dg-rotator {
  top: -25px;
  left: 50%; }

.comment-content p {
  margin-top: 0; }

.ng-select {
  margin-top: 0.1rem; }
  .ng-select *:not(button) {
    font-size: 0.7rem; }

.ng-select .ng-value-container {
  border-top: 1rem solid transparent; }

.ng-select .ng-value {
  margin-top: 0.2rem; }

.ng-select .ng-clear-wrapper {
  position: unset; }

.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  align-self: unset;
  bottom: unset; }

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding: 0; }

.ng-select.less-pl .ng-option {
  padding-left: 0.4em !important; }

.ng-select.pr-0 .ng-option {
  padding-right: 0 !important; }

.theme-light .ng-select.ng-select-focused .ng-select-container::after {
  border-bottom-color: #009bff; }

:host-context(.theme-light) .ng-select.ng-select-focused .ng-select-container::after {
  border-bottom-color: #009bff; }

.ng-select.ng-select-focused .ng-select-container::after.theme-light {
  border-bottom-color: #009bff; }

.theme-dark .ng-select.ng-select-focused .ng-select-container::after {
  border-bottom-color: #009bff; }

:host-context(.theme-dark) .ng-select.ng-select-focused .ng-select-container::after {
  border-bottom-color: #009bff; }

.ng-select.ng-select-focused .ng-select-container::after.theme-dark {
  border-bottom-color: #009bff; }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  padding: 0;
  margin: 0; }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value > span.label {
    padding: 0.5rem 0.4rem;
    font-size: 0.7rem; }

ng-select.petitSelect > .ng-select-container {
  width: 75%; }

.ng-select.no-border .ng-select-container {
  min-height: auto; }
  .ng-select.no-border .ng-select-container .ng-value-container {
    border-top: 0; }

.ng-select.hidden-arrow .ng-arrow-wrapper {
  display: none; }

.ng-option app-long-avatar .label {
  line-height: 3; }

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
  text-align: center; }

.ngx-charts {
  float: right !important;
  position: relative !important; }

ngx-charts-number-card .cell .trimmed-label p {
  line-height: 20px !important; }

.theme-light .advanced-pie-legend .legend-items-container .legend-items .legend-item:hover {
  color: #009bff; }

:host-context(.theme-light) .advanced-pie-legend .legend-items-container .legend-items .legend-item:hover {
  color: #009bff; }

.advanced-pie-legend .legend-items-container .legend-items .legend-item:hover.theme-light {
  color: #009bff; }

.theme-dark .advanced-pie-legend .legend-items-container .legend-items .legend-item:hover {
  color: #009bff; }

:host-context(.theme-dark) .advanced-pie-legend .legend-items-container .legend-items .legend-item:hover {
  color: #009bff; }

.advanced-pie-legend .legend-items-container .legend-items .legend-item:hover.theme-dark {
  color: #009bff; }

.chart-legend {
  position: relative; }
  .chart-legend .legend-labels {
    max-width: 8rem;
    line-height: 85%;
    list-style: none;
    text-align: left;
    float: left;
    width: 218% !important;
    border-radius: 3px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap; }

.theme-light .chart-legend .legend-label .active .legend-label-text {
  color: #009bff; }

:host-context(.theme-light) .chart-legend .legend-label .active .legend-label-text {
  color: #009bff; }

.chart-legend .legend-label .active .legend-label-text.theme-light {
  color: #009bff; }

.theme-dark .chart-legend .legend-label .active .legend-label-text {
  color: #009bff; }

:host-context(.theme-dark) .chart-legend .legend-label .active .legend-label-text {
  color: #009bff; }

.chart-legend .legend-label .active .legend-label-text.theme-dark {
  color: #009bff; }

.theme-light .chart-legend .legend-label .legend-label-text {
  color: #565656; }

:host-context(.theme-light) .chart-legend .legend-label .legend-label-text {
  color: #565656; }

.chart-legend .legend-label .legend-label-text.theme-light {
  color: #565656; }

.theme-dark .chart-legend .legend-label .legend-label-text {
  color: #dadada; }

:host-context(.theme-dark) .chart-legend .legend-label .legend-label-text {
  color: #dadada; }

.chart-legend .legend-label .legend-label-text.theme-dark {
  color: #dadada; }

.theme-light ngx-charts-chart {
  fill: #565656; }

:host-context(.theme-light) ngx-charts-chart {
  fill: #565656; }

ngx-charts-chart.theme-light {
  fill: #565656; }

.theme-dark ngx-charts-chart {
  fill: #dadada; }

:host-context(.theme-dark) ngx-charts-chart {
  fill: #dadada; }

ngx-charts-chart.theme-dark {
  fill: #dadada; }

.theme-light ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: #565656; }

:host-context(.theme-light) ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: #565656; }

ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.theme-light {
  color: #565656; }

.theme-dark ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: #dadada; }

:host-context(.theme-dark) ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: #dadada; }

ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.theme-dark {
  color: #dadada; }

.theme-light .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  color: #565656; }

:host-context(.theme-light) .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  color: #565656; }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover.theme-light {
  color: #565656; }

.theme-dark .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  color: #dadada; }

:host-context(.theme-dark) .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  color: #dadada; }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover.theme-dark {
  color: #dadada; }

.ng-select.ng-select-admin-access-control .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 50%; }

ng-dropdown-panel.dropdown-auto {
  width: auto !important;
  min-width: 100%; }

.mt-01 {
  margin-top: 0.1rem; }

.pt-01 {
  padding-top: 0.1rem; }

.mb-01 {
  margin-bottom: 0.1rem; }

.pb-01 {
  padding-bottom: 0.1rem; }

.ml-01 {
  margin-left: 0.1rem; }

.pl-01 {
  padding-left: 0.1rem; }

.mr-01 {
  margin-right: 0.1rem; }

.pr-01 {
  padding-right: 0.1rem; }

.mx-01,
.m-01 {
  margin-left: 0.1rem;
  margin-right: 0.1rem; }

.my-01,
.m-01 {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem; }

.px-01,
.p-01 {
  padding-left: 0.1rem;
  padding-right: 0.1rem; }

.py-01,
.p-01 {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem; }

.mt-02 {
  margin-top: 0.2rem; }

.pt-02 {
  padding-top: 0.2rem; }

.mb-02 {
  margin-bottom: 0.2rem; }

.pb-02 {
  padding-bottom: 0.2rem; }

.ml-02 {
  margin-left: 0.2rem; }

.pl-02 {
  padding-left: 0.2rem; }

.mr-02 {
  margin-right: 0.2rem; }

.pr-02 {
  padding-right: 0.2rem; }

.mx-02,
.m-02 {
  margin-left: 0.2rem;
  margin-right: 0.2rem; }

.my-02,
.m-02 {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem; }

.px-02,
.p-02 {
  padding-left: 0.2rem;
  padding-right: 0.2rem; }

.py-02,
.p-02 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem; }

.mt-04 {
  margin-top: 0.4rem; }

.pt-04 {
  padding-top: 0.4rem; }

.mb-04 {
  margin-bottom: 0.4rem; }

.pb-04 {
  padding-bottom: 0.4rem; }

.ml-04 {
  margin-left: 0.4rem; }

.pl-04 {
  padding-left: 0.4rem; }

.mr-04 {
  margin-right: 0.4rem; }

.pr-04 {
  padding-right: 0.4rem; }

.mx-04,
.m-04 {
  margin-left: 0.4rem;
  margin-right: 0.4rem; }

.my-04,
.m-04 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem; }

.px-04,
.p-04 {
  padding-left: 0.4rem;
  padding-right: 0.4rem; }

.py-04,
.p-04 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem; }

.mt-05 {
  margin-top: 0.5rem; }

.pt-05 {
  padding-top: 0.5rem; }

.mb-05 {
  margin-bottom: 0.5rem; }

.pb-05 {
  padding-bottom: 0.5rem; }

.ml-05 {
  margin-left: 0.5rem; }

.pl-05 {
  padding-left: 0.5rem; }

.mr-05 {
  margin-right: 0.5rem; }

.pr-05 {
  padding-right: 0.5rem; }

.mx-05,
.m-05 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.my-05,
.m-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.px-05,
.p-05 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.py-05,
.p-05 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.mt-07 {
  margin-top: 0.7rem; }

.pt-07 {
  padding-top: 0.7rem; }

.mb-07 {
  margin-bottom: 0.7rem; }

.pb-07 {
  padding-bottom: 0.7rem; }

.ml-07 {
  margin-left: 0.7rem; }

.pl-07 {
  padding-left: 0.7rem; }

.mr-07 {
  margin-right: 0.7rem; }

.pr-07 {
  padding-right: 0.7rem; }

.mx-07,
.m-07 {
  margin-left: 0.7rem;
  margin-right: 0.7rem; }

.my-07,
.m-07 {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem; }

.px-07,
.p-07 {
  padding-left: 0.7rem;
  padding-right: 0.7rem; }

.py-07,
.p-07 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem; }

.mt-10 {
  margin-top: 1rem; }

.pt-10 {
  padding-top: 1rem; }

.mb-10 {
  margin-bottom: 1rem; }

.pb-10 {
  padding-bottom: 1rem; }

.ml-10 {
  margin-left: 1rem; }

.pl-10 {
  padding-left: 1rem; }

.mr-10 {
  margin-right: 1rem; }

.pr-10 {
  padding-right: 1rem; }

.mx-10,
.m-10 {
  margin-left: 1rem;
  margin-right: 1rem; }

.my-10,
.m-10 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.px-10,
.p-10 {
  padding-left: 1rem;
  padding-right: 1rem; }

.py-10,
.p-10 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.mt-15 {
  margin-top: 1.5rem; }

.pt-15 {
  padding-top: 1.5rem; }

.mb-15 {
  margin-bottom: 1.5rem; }

.pb-15 {
  padding-bottom: 1.5rem; }

.ml-15 {
  margin-left: 1.5rem; }

.pl-15 {
  padding-left: 1.5rem; }

.mr-15 {
  margin-right: 1.5rem; }

.pr-15 {
  padding-right: 1.5rem; }

.mx-15,
.m-15 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.my-15,
.m-15 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.px-15,
.p-15 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.py-15,
.p-15 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.mt-20 {
  margin-top: 2rem; }

.pt-20 {
  padding-top: 2rem; }

.mb-20 {
  margin-bottom: 2rem; }

.pb-20 {
  padding-bottom: 2rem; }

.ml-20 {
  margin-left: 2rem; }

.pl-20 {
  padding-left: 2rem; }

.mr-20 {
  margin-right: 2rem; }

.pr-20 {
  padding-right: 2rem; }

.mx-20,
.m-20 {
  margin-left: 2rem;
  margin-right: 2rem; }

.my-20,
.m-20 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.px-20,
.p-20 {
  padding-left: 2rem;
  padding-right: 2rem; }

.py-20,
.p-20 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.ml-a,
.mx-a,
.m-a {
  margin-left: auto; }

.mr-a,
.mx-a,
.m-a {
  margin-right: auto; }

.mt-a,
.my-a,
.m-a {
  margin-top: auto; }

.mb-a,
.my-a,
.m-a {
  margin-bottom: auto; }

.c-grab {
  cursor: grab; }

.c-colresize {
  cursor: col-resize; }

.c-hand {
  cursor: pointer; }

.c-cell {
  cursor: cell; }

.c-default {
  cursor: default; }

.d-block {
  display: block !important; }

.d-inline {
  display: inline !important; }

.d-inlineb {
  display: inline-block !important; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.d-invisible {
  visibility: hidden; }

.d-none {
  display: none !important; }

.text-clip {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap; }

.column-container {
  display: flex;
  flex-direction: column;
  height: 100%; }

.row-container {
  flex-direction: row;
  width: 100%; }

.flex-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flex-1,
.flex-1-set {
  flex: 1; }

.flex-1-set {
  height: 1px; }

.flex-0-1 {
  flex: 0 1; }

.h-100 {
  height: 100%; }

.w-100 {
  width: 100%; }

.ox-h,
.o-h {
  overflow-x: hidden !important; }

.oy-h,
.o-h {
  overflow-y: hidden !important; }

.ox-s,
.o-s {
  overflow-x: scroll !important; }

.oy-s,
.o-s {
  overflow-y: scroll !important; }

.ox-a,
.o-a {
  overflow-x: auto !important; }

.oy-a,
.o-a {
  overflow-y: auto !important; }

.va-t {
  vertical-align: top; }

.va-m {
  vertical-align: middle; }

.va-b {
  vertical-align: bottom; }

.ws-nw {
  white-space: nowrap; }

.wb-ba {
  word-break: break-all; }

.wb-bw {
  word-break: break-word; }

.wb-ka {
  word-break: keep-all; }

.text-strike {
  text-decoration: line-through; }

.text-left {
  text-align: left !important; }

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.pos-r {
  position: relative; }

.pos-a {
  position: absolute; }

.fw-n {
  font-weight: normal; }

.fw-500 {
  font-weight: 500; }

.fw-b {
  font-weight: bold; }

.fw-ber {
  font-weight: bolder; }

.clr-col-0 {
  width: 100%;
  min-height: 1px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 1 0;
  max-width: 100%; }

.fck-word-breaker {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-word;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }

.d-flex {
  display: flex; }

.loader-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden; }

.loader-circles {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);
  width: 240px;
  height: 240px; }
  .loader-circles .circle {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: transparent;
    border: 3.33333333px solid #ffffff;
    border-radius: 50%;
    border-bottom-color: transparent;
    border-right-color: transparent;
    text-align: center; }
    .loader-circles .circle:nth-child(4n) {
      border-color: #9ed2ce;
      border-bottom-color: transparent;
      border-right-color: transparent; }
    .loader-circles .circle:nth-child(4n + 1) {
      border-color: #008abf;
      border-bottom-color: transparent;
      border-right-color: transparent; }
    .loader-circles .circle:nth-child(4n + 2) {
      border-color: #00aef2;
      border-bottom-color: transparent;
      border-right-color: transparent; }
    .loader-circles .circle:nth-child(4n + 3) {
      border-color: #47bfd8;
      border-bottom-color: transparent;
      border-right-color: transparent; }
    .loader-circles .circle:nth-child(1) {
      width: 20px;
      height: 20px;
      animation: rotate-circle linear infinite;
      animation-duration: 12s; }
    .loader-circles .circle:nth-child(2) {
      width: 40px;
      height: 40px;
      animation: rotate-circle linear infinite;
      animation-duration: 6s; }
    .loader-circles .circle:nth-child(3) {
      width: 60px;
      height: 60px;
      animation: rotate-circle linear infinite;
      animation-duration: 4s; }
    .loader-circles .circle:nth-child(4) {
      width: 80px;
      height: 80px;
      animation: rotate-circle linear infinite;
      animation-duration: 3s; }
    .loader-circles .circle:nth-child(5) {
      width: 100px;
      height: 100px;
      animation: rotate-circle linear infinite;
      animation-duration: 2.4s; }
    .loader-circles .circle:nth-child(6) {
      width: 120px;
      height: 120px;
      animation: rotate-circle linear infinite;
      animation-duration: 2s; }
    .loader-circles .circle:nth-child(7) {
      width: 140px;
      height: 140px;
      animation: rotate-circle linear infinite;
      animation-duration: 1.71428571s; }
    .loader-circles .circle:nth-child(8) {
      width: 160px;
      height: 160px;
      animation: rotate-circle linear infinite;
      animation-duration: 1.5s; }
    .loader-circles .circle:nth-child(9) {
      width: 180px;
      height: 180px;
      animation: rotate-circle linear infinite;
      animation-duration: 1.33333333s; }
    .loader-circles .circle:nth-child(10) {
      width: 200px;
      height: 200px;
      animation: rotate-circle linear infinite;
      animation-duration: 1.2s; }
    .loader-circles .circle:nth-child(11) {
      width: 220px;
      height: 220px;
      animation: rotate-circle linear infinite;
      animation-duration: 1.09090909s; }
    .loader-circles .circle:nth-child(12) {
      width: 240px;
      height: 240px;
      animation: rotate-circle linear infinite;
      animation-duration: 1s; }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-circle {
  100% {
    transform: rotate(360deg); } }

html {
  width: 100%;
  height: 100%;
  font-size: 20px !important; }

body {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  font-weight: 300; }
  .theme-light body {
    background-color: #fff;
    color: #565656; }
  :host-context(.theme-light) body {
    background-color: #fff;
    color: #565656; }
  body.theme-light {
    background-color: #fff;
    color: #565656; }
  .theme-dark body {
    background-color: #21242b;
    color: #dadada; }
  :host-context(.theme-dark) body {
    background-color: #21242b;
    color: #dadada; }
  body.theme-dark {
    background-color: #21242b;
    color: #dadada; }

body,
*,
ul,
li {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

li {
  list-style: none; }

ul.list li {
  list-style: disc;
  padding: 0.2rem 0; }

.theme-light h1, .theme-light
h2, .theme-light
h3, .theme-light
h4, .theme-light
h5, .theme-light
p {
  color: #000; }

:host-context(.theme-light) h1, :host-context(.theme-light)
h2, :host-context(.theme-light)
h3, :host-context(.theme-light)
h4, :host-context(.theme-light)
h5, :host-context(.theme-light)
p {
  color: #000; }

h1.theme-light,
h2.theme-light,
h3.theme-light,
h4.theme-light,
h5.theme-light,
p.theme-light {
  color: #000; }

.theme-dark h1, .theme-dark
h2, .theme-dark
h3, .theme-dark
h4, .theme-dark
h5, .theme-dark
p {
  color: #fff; }

:host-context(.theme-dark) h1, :host-context(.theme-dark)
h2, :host-context(.theme-dark)
h3, :host-context(.theme-dark)
h4, :host-context(.theme-dark)
h5, :host-context(.theme-dark)
p {
  color: #fff; }

h1.theme-dark,
h2.theme-dark,
h3.theme-dark,
h4.theme-dark,
h5.theme-dark,
p.theme-dark {
  color: #fff; }
